import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/secu';
  return basic_url + url;
}

const secuAPI = {
  // 获取报警点
  getPointList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/point/queryList'), method: 'post', data }, option));
  },
  // 查看摄像头
  getCameraPointBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/cameraPointBind/detailCamera'), method: 'post', data }, option));
  },
}

export default secuAPI;