/*
 * @Author: your name
 * @Date: 2019-08-01 09:37:32
 * @LastEditTime: 2020-11-18 17:26:16
 * @LastEditors: zxx
 * @Description: In User Settings Edit
 * @FilePath: \xinsec-iot-zhaoqing\src\router\index.js
 */
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [
  { // 重定向
    path: '/',
    redirect: '/menu',
    component: () => import('@/layout'),
    hidden: true
  },
  { // 首页
    path: '/home',
    component: () => import('@/views/home'),
    hidden: true
  },
  { // 报表、大屏应用嵌入的角色管理
    path: '/lonerole',
    component: () => import('@/views/sys/role/index2'),
    hidden: true
  },
  { // 报表、大屏应用嵌入的用户管理
    path: '/loneuser',
    component: () => import('@/views/sys/user/index2'),
    hidden: true
  },
  { // 报表、大屏应用嵌入的用户编辑
    path: '/loneedit',
    component: () => import('@/views/sys/user/edit/index2'),
    hidden: true
  },
  { // 报表、大屏应用嵌入的用户新增
    path: '/loneadd',
    component: () => import('@/views/sys/user/add/index2'),
    hidden: true
  },
  { // 报表、大屏应用嵌入的用户回收箱
    path: '/lonebins',
    component: () => import('@/views/sys/user/bins/index2'),
    hidden: true
  },
  { // 报表、大屏应用嵌入的用户回收箱查看
    path: '/lonelook',
    component: () => import('@/views/sys/user/bins/look/index2'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/not-login',
    component: () => import('@/views/login/not-login'),
    name: 'not-login',
    meta: {
      title: '登录'
    }
  },
  {
    path: '/assets-all-map1',
    component: () => import('@/views/assets/all/all-map.vue'),
    name: 'assets-all-map',
    meta: {
      title: '资产管理 - 地图模式'
    }
  },
  { // 登录 - 跳转
    path: '/login-to',
    component: () => import('@/views/login/login-to'),
    name: 'login-to',
    meta: {
      title: '登录'
    }
  },
  {
    path: '/skip',
    component: () => import('@/views/login/skip'),
    hidden: true
  },
  {
    path: '/yizhu',
    component: () => import('@/views/login/yizhu'),
    hidden: true
  },
  {
    path: '/menu',
    component: () => import('@/views/login/menu'),
    hidden: true,
    meta: {
      title: '主菜单'
    }
  },
  {
    path: '/monitor',
    component: () => import('@/views/sys/monitor/monitor.vue'),
    hidden: true
  },
  // // 医护消息看护路由
  // {
  //   path: '/medical',
  //   component: () => import('@/layout'),
  //   hidden: true,
  //   children: [
  //     {
  //       path: '/medical/notification',
  //       component: () => import('@/views/medical/notification/index.vue'),
  //       hidden: true
  //     },
  //     {
  //       path: '/settings',
  //       component: () => import('@/views/medical/settings/index.vue'),
  //       hidden: true
  //     },
  //     {
  //       path: '/wristband',
  //       component: () => import('@/views/medical/wristband/index.vue'),
  //       hidden: true
  //     },
  //   ]
  // },
  {
    path: '/infu/monitor',
    component: () => import('@/views/transfusion/monitor/monitor.vue'),
    hidden: true
  },
  { // 智能输液 - 竖屏看板
    path: '/transfusion/vertical-screen',
    component: () => import('@/views/transfusion/vertical-screen/index.vue'),
    hidden: true
  },
  { // 智慧看护
    path: '/care/patientfull',
    component: () => import('@/views/care/patient/index.vue'),
    hidden: true,
    meta: {
      title: '智慧看护'
    }
  },
  { // 历史记录
    path: '/care/signHistoryfull',
    component: () => import('@/views/care/settings/sign-history.vue'),
    hidden: true,
    meta: {
      title: '历史记录'
    }
  },
  { // 记录
    path: '/care/patientRecordfull',
    component: () => import('@/views/care/patient/patient-record.vue'),
    hidden: true,
    meta: {
      title: '记录'
    }
  },
  { // 定位
    path: '/care/mapfull',
    component: () => import('@/views/care/map/index.vue'),
    hidden: true,
    meta: {
      title: '定位'
    }
  },
  { // 历史轨迹
    path: '/care/trackfull',
    component: () => import('@/views/care/track/index.vue'),
    hidden: true,
    meta: {
      title: '历史轨迹'
    }
  },
  {
    path: '/nurseScreen',
    component: () => import('@/views/nurse/screen/index.vue'),
    hidden: true
  },
  {
    path: '/patientViewDetail',
    component: () => import('@/views/nurse/screen/PatientViewModel/patientDetail.vue'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true
  },
  {
    path: '/mobileCareMap',
    component: () => import('@/views/mobile/mobile-care-map.vue'),
    hidden: true
  },
  {
    path: '/mobileCareTrack',
    component: () => import('@/views/mobile/mobile-care-track')
  },
  {
    path: '/threeBcg',
    component: () => import('@/views/care/patient/threeBcg.vue'),
    hidden: true
  },
  {
    path: '/humitureMoblie',
    component: () => import('@/views/humiture/mobile'),
    meta: {
      title: 'pad用图表',
      active: 'humiture-moblie'
    }
  },
  {
    path: '/thirdLogin',
    component: () => import('@/views/login/thirdLogin'),
    hidden: true
  },
  {
    path: '/passwordExpired',
    component: () => import('@/views/login/passwordExpired'),
    hidden: true
  },
  {
    path: '/emerLabel',
    component: () => import('@/views/emer/emerLabel'),
    hidden: true
  },
  {
    path: '/custom-historyRoute',
    component: () => import('@/views/emer/disease/custom-historyRoute'),
    hidden: true
  },
  {
    path: '/custom-realtTimeRoute',
    component: () => import('@/views/emer/realtime/custom-historyRoute'),
    hidden: true
  },
  {
    path: '/PDA-H5-pectoralgia',
    component: () => import('@/views/pectoralgia/disease/PDA/management-table')
  },
  {
    path: '/PDA-custom-historyRoute',
    component: () => import('@/views/pectoralgia/disease/PDA/custom-historyRoute'),
    hidden: true
  },
  {
    path: '/PDA-custom-realtTimeRoute',
    component: () => import('@/views/pectoralgia/disease/PDA/custom-realtRoute'),
    hidden: true
  },
  // PDA - 查看位置
  {
    path: '/help/PDA/map.vue',
    component: () => import('@/views/help/PDA/map.vue'),
    hidden: true
  },
  {
    path: '/thridLoginCare',
    name: 'thridLoginCare',
    component: () => import('@/views/login/thridLoginCare'),
    hidden: true
  },
  {
    path: '/printPage',
    name: 'printPage',
    meta: {
      title: '打印页面',
    },
    component: () => import('@/views/assets/printPage/index.vue'),
    hidden: true
  },
  
];

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  // routes: globalRoutes.concat(mainRoutes),
  routes: constantRoutes,
  isDynamicRouter: false // 是否动态路由
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter();
  console.log('resetRouter');
  router.matcher = newRouter.matcher; // reset router
  router.options.isDynamicRouter = false;
}

export default router;
