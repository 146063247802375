import router, { constantRoutes } from './router';
import store from '@/store';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken, setToken } from '@/utils/auth'; // get token
import getPageTitle from '@/utils/get-page-title';
import { conversionUrl } from '@/utils/common';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

// 自定义路由名称 - 不走后台菜单权限
const whiteList = [
  '/thridLoginCare',
  '/login-to',
  '/not-login',
  '/home',
  '/login',
  '/skip/',
  '/skip',
  '/yizhu',
  '/threeBcg',
  '/humitureMoblie',
  '/thirdLogin',
  '/passwordExpired',
  '/mobileCareMap',
  '/custom-historyRoute',
  '/custom-realtTimeRoute',
  '/emerLabel',
  '/PDA-H5-pectoralgia',
  '/PDA-custom-historyRoute',
  '/PDA-custom-realtTimeRoute',
  '/transfusion/vertical-screen',
  '/medical/notification',
  '/care/patientfull',
  '/care/signHistoryfull',
  '/care/patientRecordfull',
  '/care/mapfull',
  '/care/trackfull',
  '/printPage',
];

const permissionRoutes = [{
  path: '*',
  redirect: '/404',
  hidden: true
}];

router.beforeEach(async (to, from, next) => {
  let withoutRes = null;
  console.log('permission路由导航卫士(to):', to);
  console.log('permission路由导航卫士(from):', from);
  console.log('whiteList.indexOf(to.path)', whiteList.indexOf(to.path));
  if (whiteList.indexOf(to.path) === -1 && to.query.appId && Number(to.query.appId) !== store.state.userHandle.userInfo.appId) {
    withoutRes = await store.dispatch('userHandle/GET_ROUTER', to.query.appId);
    console.log('resresres', withoutRes);
  } else {
    withoutRes = null;
  }
  // 安全求助需求，监控大屏路由要跳到指定链接地址
  if (to.path === '/help/screen' && store.state.common.screenUrl) {
    // window.open('http://10.80.210.82:82/#/visual-report/child-screen/index?childId=117&childType=3');
    window.open(store.state.common.screenUrl);
  }
  // 安全求助模块 === PDA的H5地图页面
  if (to.path === '/help/PDA/map.vue') {
    store.commit('userHandle/SET_TOKEN', to.query.token);
    store.commit('userHandle/SET_USERINFO', { hospitalId: to.query.hospitalId }); // 根据院区ID请求地图
    setToken(to.query.token);
  }
  // 在路由全局钩子beforeEach中，根据keepAlive属性，统一设置页面的缓存性
  // 作用是每次进入该组件，就将它缓存
  if (to.meta.keepAlive) {
    store.commit('common/keepAlive', to.meta.componentName);
    if (store.state.common.keepAliveComponents.length > 2) {
      // 删除页面缓存
      store.commit('common/noKeepAlive', store.state.common.keepAliveComponents[0]);
    }
    console.log('在路由全局钩子beforeEach中，根据keepAlive属性，统一设置页面的缓存性componentNamekeepAliveComponents', store.state.common.keepAliveComponents);
  }
  NProgress.start(router.options);

  document.title = getPageTitle(to.meta.title);
  // determine whether the user has logged in
  const hasToken = getToken();
  let loginCode = store.state.userHandle.loginCode;
  if (loginCode === 12138) {
    loginCode = Number(sessionStorage.getItem('loginCode'));
  }
  console.log('permission路由导航卫士:登录code值', loginCode);// 对于登录过期做特殊判断
  console.log('permission路由导航卫士:是否有token', hasToken);
  console.log(loginCode, '登录code值');// 对于登录密码过期做特殊判断
  console.log(hasToken, '是否有token');
  if (hasToken && loginCode !== 301013) { // 登录密码过期特殊判断
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      if (withoutRes) {
        next({ path: withoutRes.path });
        NProgress.done();
        return
      }
      // 动态路由
      if (to.matched.length === 0) {
        let obj = store.state.userHandle.router;
        obj.component = () => import(`@/layout/index.vue`);
        obj.children.forEach(item => {
          item.component = conversionUrl(item.path);
        });
        let routerArr = [];
        if (obj.path) {
          routerArr = constantRoutes.concat([obj], permissionRoutes);
        } else {
          routerArr = constantRoutes.concat(permissionRoutes);
        }
        console.log('routerArrrouterArr', routerArr);
        router.addRoutes(routerArr);
        next({ ...to, replace: true });
      } else {
        next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      if (to.query.token) {
        setToken(to.query.token);
      }
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});

// 有地方应用到，但是没有这个定义
export const fnAddDynamicMenuRoutes = (item) => {
  return item;
};
