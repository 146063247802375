import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';
function Basic_Url (url) {
  let basic_url = '/api/sys';
  return basic_url + url;
}

const sysAPI = {
  // 查询院方信息
  getcourtyardInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/info/list'), method: 'post', data }, option));
  },
  // 查询医院下的相亲信息
  hospitalDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/detail'), method: 'post', data }, option));
  },
  // 登录
  login (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/loginV2'), method: 'post', data }, option));
  },
  // 主页信息 - 用户信息，菜单权限等
  getHomeInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/homeInfo'), method: 'post', data }, option));
  },
  // 登录信息
  getLoginInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appInfo'), method: 'post', data }, option));
  },
  // 获取应用菜单列表
  getLoginAppMenu  (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/menuV2/queryLoginAppMenu'), method: 'post', data }, option));
  },
  // 修改用户密码（修改自己的密码）
  setSelfPassword (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/changePwdV2'), method: 'post', data }, option));
  },
  // ------------------------------------
  // 管理员管理
  // 获取用户列表
  getUserList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/user/listPage'), method: 'post', data }, option));
  },
  // 获取用户列表(不分页)
  getAllUserList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/user/list'), method: 'post', data }, option));
  },
  // 获取用户详情
  getUserMessage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/user/detail'), method: 'post', data }, option));
  },
  // 查询用户配置
  getUserConfigDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/user/config/detail'), method: 'post', data }, option));
  },
  // 更新用户配置
  updateUserConfigDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/user/config/update'), method: 'post', data }, option));
  },
  // 重置用户密码
  resetUserPassword (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/resetPwd'), method: 'post', data }, option));
  },
  // --------------------------------
  // 角色管理
  // 获取位置字典
  getSiteFloorDict (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/listPage'), method: 'post', data }, option));
  },
  // 切换报警声音
  saveAlarmConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/saveAlarmConfig'), method: 'post', data }, option));
  },
  // 获取字典列表(分页)
  getDictListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/listPage'), method: 'post', data }, option));
  },
  // 获取字典列表(不分页) "dictType": "INFU_COEFFICIENT"//滴速系数类型
  getDictList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/list'), method: 'post', data }, option));
  },
  // 新增字典
  addDict (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/add'), method: 'post', data }, option));
  },
  // 修改字典
  updateDict (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/update'), method: 'post', data }, option));
  },
  // 批量修改字典
  updateBatchConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/updateBatchConfig'), method: 'post', data }, option));
  },
  // 删除字典
  deleteDict (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/delete'), method: 'post', data }, option));
  },
  // 获取字典详情
  getDictDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/detail'), method: 'post', data }, option));
  },
  // 查询报警音开关设置
  getQueryAlarmSwitch (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/queryAlarmSwitch'), method: 'post', data }, option));
  },
  // 修改报警音开关设置
  updateAlarmSwitch (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dict/update'), method: 'post', data }, option));
  },
  // 获取当前系统服务器时间
  getNowSysTime (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/time'), method: 'get', data }, option));
  },
  // 修改应用插件
  updateAppPlugin (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appPlugin/update'), method: 'post', data }, option));
  },
  // 新增应用插件
  addAppPlugin (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appPlugin/add'), method: 'post', data }, option));
  },
  // 应用插件详情
  appPluginDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appPlugin/detail'), method: 'post', data }, option));
  },
  // 应用插件列表
  getAppPluginList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appPlugin/list'), method: 'post', data }, option));
  },
  // 应用插件删除
  delAppPlugin (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appPlugin/delete'), method: 'post', data }, option));
  },
  // 上传记录列表
  getUploadLogList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/logList'), method: 'post', data }, option));
  },

  // 应用列表
  getAppList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/list'), method: 'post', data }, option));
  },
  // 应用新增
  addApp (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/add'), method: 'post', data }, option));
  },
  // 应用修改
  updateApp (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/update'), method: 'post', data }, option));
  },
  // 应用详情
  getAppDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/detail'), method: 'post', data }, option));
  },
  // 应用删除
  delApp (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/delete'), method: 'post', data }, option));
  },
  // 关联应用权限列表
  getAppMenuList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/appMenuList'), method: 'post', data }, option));
  },
  // 品牌列表
  getAppBrandList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/app/brandList'), method: 'post', data }, option));
  },
  // 楼层新增/编辑/删除
  systemFloor (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/floor/add'), method: 'post', data }, option));
  },
  // 查询楼层映射列表
  getFloorList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/floor/list'), method: 'post', data }, option));
  },
  // 楼栋信息分页列表
  getBuildingListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/building/listPage'), method: 'post', data }, option));
  },
  // 查询应用角色列表
  appRoleList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appRole/list'), method: 'post', data }, option));
  },
  // 查询应用角色详情
  appRoleDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appRole/detail'), method: 'post', data }, option));
  },
  // 查询应用列表
  appRoleQueryApp (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appRole/queryApp'), method: 'post', data }, option));
  },
  // 角色新增
  appRoleAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appRole/add'), method: 'post', data }, option));
  },
  // 角色删除
  appRoleDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appRole/delete'), method: 'post', data }, option));
  },
  // 角色修改
  appRoleUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/appRole/update'), method: 'post', data }, option));
  },
  // 获取菜单列表 - 权限用
  getMenuList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/menuV2/list'), method: 'post', data }, option));
  },
  // 医院管理 - 列表
  getHospitalListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/listPage'), method: 'post', data }, option));
  },
  // 医院管理 - 详情
  getHospitalDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/detail'), method: 'post', data }, option));
  },
  // 医院管理 - 修改
  updateHospitalDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/hospital/update'), method: 'post', data }, option));
  },
  // 楼栋管理 - 新增
  buildingAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/building/add'), method: 'post', data }, option));
  },
  // 楼栋管理 - 更新状态
  buildingUpdateStatus (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/building/updateStatus'), method: 'post', data }, option));
  },
  // 楼栋管理 - 楼栋信息详情
  buildingDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/building/detail'), method: 'post', data }, option));
  },
  // 楼栋管理 - 更新信息
  buildingUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/building/update'), method: 'post', data }, option));
  },
  // 楼栋管理 - 楼栋删除
  buildingDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/building/delete'), method: 'post', data }, option));
  },
  // 病区管理 - 新增病区
  areaAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/add'), method: 'post', data }, option));
  },
  // 病区管理 - 病患来源
  areaConfigDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/config/detail'), method: 'post', data }, option));
  },
  // 病区管理 - 病患来源
  areaConfigUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/config/update'), method: 'post', data }, option));
  },
  // 病区管理 - 修改病区
  areaUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/update'), method: 'post', data }, option));
  },
  // 病区管理 - 删除病区
  areaDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/delete'), method: 'post', data }, option));
  },
  // 病区管理 - 数据同步
  synchrAreaData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/area/synchrAreaData'), method: 'post', data }, option));
  },
  // 获取位置信息 - 列表
  buildingSiteList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/buildingSite/list'), method: 'post', data }, option));
  },
  // 科室管理 - 科室列表(分页)
  deptListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/listPage'), method: 'post', data }, option));
  },
  // 科室管理 - 科室列表(不分页)
  deptList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/list'), method: 'post', data }, option));
  },
  // 科室管理 - 数据同步
  synchrDeptData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/synchrDeptData'), method: 'post', data }, option));
  },
  // 科室管理 - 添加
  deptAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/add'), method: 'post', data }, option));
  },
  // 科室管理 - 编辑
  deptUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/update'), method: 'post', data }, option));
  },
  // 科室管理 - 删除
  deptDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/dept/delete'), method: 'post', data }, option));
  },
  // 床位管理 - 添加
  bedAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/add'), method: 'post', data }, option));
  },
  // 床位管理 - 编辑
  bedUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/update'), method: 'post', data }, option));
  },
  // 床位管理 - 批量导入
  bedImport (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/import'), method: 'post', data }, option));
  },
  // 床位管理 - 删除
  bedDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/delete'), method: 'post', data }, option));
  },
  // 床位管理 - 数据同步
  bedSynchrBedData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bed/synchrBedData'), method: 'post', data }, option));
  },
  // 获取用户列表（分页）
  getUserlistPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/listPage'), method: 'post', data }, option));
  },
  // 获取用户列表
  getUserlist (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/list'), method: 'post', data }, option));
  },
  // 新增用户信息
  userAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/add'), method: 'post', data }, option));
  },
  // 修改用户信息
  userUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/update'), method: 'post', data }, option));
  },
  // 删除用户信息
  userDel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/delete'), method: 'post', data }, option));
  },
  // 查看用户信息
  userDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/detailV2'), method: 'post', data }, option));
  },
  // 恢复用户
  userRecover (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/recover'), method: 'post', data }, option));
  },
  // 校验工号
  userCheckUserNo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userV2/checkUserNo'), method: 'post', data }, option));
  },
  // 查询用户应用列表
  userAppList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userApp/list'), method: 'post', data }, option));
  },
  // 查询应用菜单
  queryAppMenu (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userApp/queryAppMenu'), method: 'post', data }, option));
  },
  // 修改用户应用
  userAppUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userApp/update'), method: 'post', data }, option));
  },
  // 用户功能权限列表
  userRoleList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userRoleV2/list'), method: 'post', data }, option));
  },
  // 修改用户功能权限
  userRoleUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userRoleV2/update'), method: 'post', data }, option));
  },
  // 用户数据权限列表
  userAreaList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userAreaV2/list'), method: 'post', data }, option));
  },
  // 用户修改数据权限
  userAreaUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userAreaV2/update'), method: 'post', data }, option));
  },
  // 用户修改数据权限-科室
  userDeptUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userDeptV2/update'), method: 'post', data }, option));
  },
  // 用户数据权限列表-科室
  userDeptList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userDeptV2/list'), method: 'post', data }, option));
  },
  // 查询配置列表
  configList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/list'), method: 'post', data }, option));
  },
  // 修改登录时效配置列表
  configBatchUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/config/batchUpdate'), method: 'post', data }, option));
  },
  // 位置权限列表
  userSiteList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userSite/list'), method: 'post', data }, option));
  },
  // 修改位置权限
  userSiteUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/userSite/update'), method: 'post', data }, option));
  },
  // 系统日志列表查询
  logListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/log/listPage'), method: 'post', data }, option));
  },

  // 手动新增病患
  patientAddPatient (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/patient/addPatient'), method: 'post', data }, option));
  },

  // 更新 token
  findNoUsePass (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/findNoUsePassV2'), method: 'post', data }, option));
  },

  // 客户信息管理 - 房间管理 - 新增房间
  addRoom (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/room/add'), method: 'post', data }, option));
  },

  // 客户信息管理 - 房间管理 - 查询房间列表-分页
  getRoomList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/room/listPage'), method: 'post', data }, option));
  },

  // 客户信息管理 - 房间管理 - 查询房间列表
  getRoomAllList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/room/list'), method: 'post', data }, option));
  },

  // 客户信息管理 - 房间管理 - 查询房间详情
  getRoomDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/room/detail'), method: 'post', data }, option));
  },

  // 客户信息管理 - 房间管理 - 修改房间
  editRoom (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/room/update'), method: 'post', data }, option));
  },

  // 客户信息管理 - 房间管理 - 删除房间
  deleteRoom (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/room/delete'), method: 'post', data }, option));
  },
  // 第三方登录 - 智慧看护
  thridLoginCare (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/thrid/user/login/ybey', method: 'post', data }, option));
  },
  // 设置详情
  getConfigDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/config/detail', method: 'post', data }, option));
  },
  // 修改LOGO设置
  updateConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/config/update', method: 'post', data }, option));
  },
  // ------------2.6.0 护士管理----http://showdoc.xinsec.com.cn/web/#/83/4858-------
  // 分页查询护士
  getNurseList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/user/bed/userListPage', method: 'post', data }, option));
  },
   // 护士管理床位详情
   getNurseBedDetail(data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/user/bed/detail', method: 'post', data }, option));
  },
   // 更新护士管理床位
   updateNurseBed (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/user/bed/update', method: 'post', data }, option));
  },
   // 查询设备列表
   getNurseDeviceList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/user/device/deviceList', method: 'post', data }, option));
  },
   // 护士使用设备详情
   getNurseDeviceDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/user/device/detail', method: 'post', data }, option));
  },
  // 护士使用设备添加
  addDeviceForNurse (data = {}, option = {}) {
      return axios.request(Object.assign({ url: PROXY + '/api/sys/user/device/add', method: 'post', data }, option));
    },
  // 用户使用设备更新
  updateUserDevice (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/user/device/update', method: 'post', data }, option));
  },
   // 用户使用设备删除
   deleteUserDevice (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/user/device/delete', method: 'post', data }, option));
  },
  // 通过病区获取所有床位
  getAllBedList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/bed/list', method: 'post', data }, option));
  },
  // 查询科室病区信息
  getAllDeptArea (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/area/queryDeptArea', method: 'post', data }, option));
  },
  // 系统管理3.0.7&智慧看护2.6.5
  // 截取床位别名
  // 查询科室病区信息
  subBedName (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/bed/subBedName', method: 'post', data }, option));
  },
  getAreaDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/area/detail', method: 'post', data }, option));
  },

  // 资产2.3.17 - 空间管理 ===============

  // 新增空间接口
  getSysSpaceAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/SysSpace/add', method: 'post', data }, option));
  },
  // 修改空间接口
  getSysSpaceUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/SysSpace/update', method: 'post', data }, option));
  },
  // 删除空间接口
  getSysSpaceDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/SysSpace/delete', method: 'post', data }, option));
  },
  // 空间列表分页接口
  getSysSpaceListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/SysSpace/listPage', method: 'post', data }, option));
  },
  // 空间列表地图模式接口
  getSysSpaceList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/SysSpace/list', method: 'post', data }, option));
  },

};

export default sysAPI;

// 只导出获取楼层列表
export const getFloorList = sysAPI.getFloorList;
