import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
const cn = {
  // 导航栏
  navBar: {
    logout: '退出登录',
    languageSet: '语言设置',
    ChangePassword: '修改密码'
  },
  // 环境
  config: {
    // devTitle: '广州医科大学附属第二医院 医疗物联网应用系统',
    // devTitle: '首都医科大学宣武医院 医疗物联网应用系统',
    devTitle: '医疗物联网应用系统',
    // devTitle: '阳江市人民医院医疗物联网应用系统',
    // devTitle: '重庆市沙坪坝区中医院 医疗物联网应用系统',
    // devTitle: '信尚安物联医疗物联网应用系统',
    // devTitle: '九龙坡区人民医院 医疗物联网应用系统',
    // devTitle: '重庆市沙坪坝区中医院 医疗物联网应用系统',
    devLoginTitle: '医疗物联网应用平台'
  },
  // 登录
  login: {
    logIn: '登 录',
    username: '账号',
    enterUsername: '请输入用户名',
    password: '请输入密码',
    usernameEmpty: '帐号不能为空',
    passwordEmpty: '密码不能为空',
    versionNumber: '版本号',
    logging: '登录中',
    copyright: '广东信尚安物联科技有限公司版权所有'
  },
  // 常见使用
  common: {
    Explain: '处理说明',
    disable: '禁用',
    normal: '正常',
    sysSet: '系统设置',
    homePage: '首页',
    second: '秒',
    min: '分钟',
    bed: '床',
    edit: '编辑',
    other: '其他',
    hint: '提示',
    left: '左',
    right: '右',
    man: '男',
    woman: '女',
    unknown: '未知',
    none: '没有',
    have: '有',
    inHos: '在院',
    outHos: '离院',
    cancel: '取 消',
    confirm: '确 定',
    all: '全部',
    name: '姓名',
    sex: '性别',
    age: '年龄',
    phone: '电话',
    nowStatus: '当前状态',
    hosNum: '住院号',
    bedNum: '床号',
    listMode: '列表模式',
    bedMode: '床位模式',
    inpatientWard: '病区',
    selectKeyword: '请输入关键字搜索',
    status: '状态',
    selectStatus: '请选择状态',
    isbinding: '已绑定',
    notBound: '未绑定',
    isbind: '已绑',
    change: '更换',
    operation: '操作',
    device: '设备',
    deviceRecord: '设备记录',
    detail: '详情',
    changeDevice: '更换设备',
    bindingDevice: '绑定设备',
    distributionEquipment: '设备管理',
    bindingSuccess: '绑定成功',
    changebindSuccess: '更换成功',
    unbindSuccess: '解绑成功',
    fiveMin: '5 分钟后',
    tenMin: '10 分钟后',
    thirtyMin: '30 分钟后',
    minLater: '分钟后',
    pleaseInput: '请输入',
    pleaseSelect: '请选择',
    newSuccess: '新建成功',
    remain: '剩余',
    residue: '剩余量',
    speed: '速度',
    total: '总量',
    type: '类型',
    selectType: '请选择类型',
    unconfirmed: '未确认',
    confirmed: '已确认',
    alarmType: '报警类型',
    alarmInfo: '报警信息',
    alarmTime: '报警时间',
    alarmLevel: '报警级别',
    confirmTime: '确认时间',
    check: '查看',
    new: '新增',
    leastValue: '最小值',
    maxValue: '最大值',
    createTime: '创建时间',
    updateTime: '更新时间',
    remark: '备注',
    del: '删除',
    close: '关闭',
    open: '开启',
    minute: '分钟',
    save: '保存',
    custom: '自定义',
    default: '默认',
    next: '次',
    formatNum: '格式错误，仅限数字',
    tenChar: '只能输入十个字符',
    delSuccess: '删除成功',
    saveSuccess: '保存成功',
    editSuccess: '修改成功',
    cancelledDelete: '已取消删除',
    deviceType: '设备类型',
    bindingState: '绑定状态',
    divideState: '分配状态',
    useState: '使用状态',
    equipment: '设备',
    EquipmentStatus: '设备状态',
    selectDeviceType: '请选择设备类型',
    enterSearchKeyword: '请输入搜索关键字',
    pptDowload: '模板下载',
    bulkImport: '批量导入',
    binding: '绑定',
    online: '在线',
    outline: '离线',
    deviceAbnormal: '设备异常',
    template: '模板',
    errorDataExport: '导出数据出错',
    sureDelDevice: '确定删除此设备?',
    enterDeviceID: '请输入设备ID',
    clickUpload: '点击上传',
    selectFile: '请选择文件',
    importSuccess: '批量导入成功',
    uploadMost: '最多只能上传',
    afile: '个文件',
    selectBindState: '请选择绑定状态',
    departments: '科室',
    selectSection: '请选择科室',
    area: '病区',
    WardName: '病区名称',
    WardCode: '病区编码',
    areaDictName: '病区字典',
    selectArea: '请选择病区(支持搜索)',
    selectAreaTips: '请选择病区',
    adf: '所属科室',
    roomNumber: '房号',
    bedNo: '床号',
    bedNumber: '床位数',
    BedDictionary: '床位字典',
    changeBind: '更绑',
    unbind: '解绑',
    relieve: '解除',
    replace: '更换',
    deleteCurrentBed: '确定删除当前床位?',
    unbindCurrentDevice: '确定解绑此设备?',
    changeBinding: '更改绑定',
    changeDistribution: '更改分配',
    distribution: '分配',
    AllocationStatus: '分配状态',
    selectBindType: '请选择绑定类型',
    changeBindSuccess: '更绑成功',
    selsectRoomNum: '请选择房号',
    enterRoomNum: '请输入房号',
    selsectBedNum: '请选择床号',
    enterBedNum: '请输入床号',
    messageRemind: '消息提醒',
    affirm: '确认',
    affirmSuccess: '确认成功',
    remindTimeOver: '提醒时间到',
    processed: '已处理',
    unprocessed: '未处理',
    viewed: '已查看',
    transboundaryAlarm: '越界报警',
    oneButtonAlarm: '一键报警',
    dismantlTheAlarm: '拆除报警',
    cancelHelp: '取消求助',
    level: '等级',
    class: '级',
    getInto: '进入',
    leave: '离开',
    processingTime: '处理时间',
    handle: '处理',
    year: '年',
    month: '月',
    reqERR: '请求错误',
    for: '对',
    deleting: '进行删除',
    deleting1: '进行彻底删除',
    modify: '修改',
    operationS: '操作成功',
    gotIt: '知道了',
    optionDate: '选择日期',
    startTime: '开始时间',
    endTime: '结束时间',
    selectTimeRange: '选择时间范围',
    floor: '楼层',
    tower: '楼',
    stop: '停止',
    ward: '病房',
    hosBed: '病床',
    success: '成功',
    admissionTime: '入院时间',
    outHosTime: '离院时间',
    deviceName: '设备名称',
    coord: '坐标',
    address: '地址',
    importFromTemplate: '从模板中导入',
    No: '序号',
    with: '与',
    shouldBluetoothBracelet: '将蓝牙手环',
    viewLocation: '查看位置',
    OneKeyProcessing: '一键处理',
    closedAndProcessed: '关闭并已处理',
    AlarmLevel: '报警等级',
    handleSuccess: '处理成功',
    alarmTips: '报警音已关闭，是否需要开启？',
    yes: '是',
    no: '否',
    search: '搜索',
    selectDateTips: '请选择日期',
    selectEndTimeTips: '请选择结束时间',
    processInfo: '处理信息',
    cantSeeMapTips: '无法查看地图位置',
    screen: '筛选',
    years: '岁',
    editProfile: '修改资料',
    forDetails: '查询详情',
    hardLoading: '努力加载中',
    noData: '暂无数据',
    add: '添加',
    inputName: '请输入姓名',
    selectDateTime: '选择日期时间',
    endTimeCannotEmpty: '结束时间不能为空',
    timeFormatNotCorrect: '时间格式不正确',
    chooseEarlierThan: '选择的时间不能早于',
    patientIDcannotEmpty: '患者ID不能为空',
    category: '类别',
    noDataFound: '没有找到数据',
    nothing: '无',
    grade: '评分',
    updateFailedTips: '更新失败，请重试',
    activated: '已激活',
    // enterRemarks: '',
    AlarmFromHospital: '离院报警',
    StartDate: '起始日期',
    AllOneClickProcessing: '一键处理',
    historicalInformation: '历史消息',
    syncSuccess: '同步成功',
    strip: '条',
    Binding: '绑定情况',
    bedID: '床垫ID',
    enterSearchBedNum: '请输入床号搜索',
    Bed: '床垫',
    NewBed: '新增床垫',
    Assigned: '已分配',
    Unassigned: '未分配',
    ItisBottom: '已经最底了',
    ReleasedSuccessfully: '解除成功',
    AssignmentSuccessful: '绑定成功',
    TipsInfo: '提醒信息',
    DirectProcessing: '直接处理',
    ProcessAndComment: '处理并备注',
    alarmLocation: '报警位置',
    NoNews: '暂无消息',
    NoprocessNews: '历史未处理消息',
    NowUnProcessNews: '当前未处理消息',
    NowUnReadNews: '未读消息',
    DealAll: '全部处理',
    date: '日期',
    choice: '选择',
    addName: '新增名单',
    Scheduling: '排班',
    Reporting: '上报',
    amount: '数量',
    retry: '重试',
    detailed: '明细',
    determine: '确定',
    RealTimeLocation: '实时位置',
    TipsTime: '提醒时间',
    function: '功能',
    submitSuccess: '提交成功',
    selectRegion: '请选择区域'

  },

  // 语言
  language: {
    tips: '切换语言成功'
  },
  // home页
  home: {
    title: '欢迎使用信尚安物联网系统',
    // title: '欢迎使用智能输液系统',
    loadingText: '拼命加载中',
    clickLeft: '请点击左侧菜单栏进行相关操作'
  },
  // 智能输液
  transfusion: {
    automaticEnd: '自动结束输液',
    inTheInfusion: '输液中',
    stopInfu: '输液暂停',
    infusionInsuf: '余量不足',
    infusionOut: '即将输完',
    weightAbnormal: '重量超重',
    deviceOffline: '设备离线',
    infusionOver: '药液输完',
    abnormalData: '数据异常',
    aneroidAlarm: '无液报警',
    speedAnomalies: '滴速异常',
    infusionJam: '输液堵塞',
    speedToFast: '滴速过快',
    speedToSlow: '滴速过慢',
    batteryLow: '电量不足',
    batteryAmple: '电量充足',
    lowBattery: '低电量',
    callHelp: '呼叫求助',
    sort: '床号显示',
    bedSort: '床号排序',
    marginSort: '余量排序',
    batterySort: '电量排序',
    endSort: '预计时间排序',
    expectedEnd: '预计结束',
    liquidMargin: '液体余量',
    electricQuantity: '电量',
    timeRemind: '计时提醒',
    marginRemind: '余量提醒',
    memo: '备忘',
    memorandum: '备忘录',
    margin: '余量',
    infusionTerminal: '输液终端',
    selsetTerminal: '请选择输液终端ID',
    wirelessPager: '无线呼叫器',
    selectWireless: '请选择无线呼叫器ID',
    unbindTerminal: '解绑输液终端',
    unbindWireless: '解绑无线呼叫器',
    unbindTerminalText: '解除则无法实时监测到输液进展，请谨慎操作！',
    isCheckUnbindTerminal: '是否解除输液终端？',
    unbindWirelessText: '解除绑定则患者无法求助，请谨慎操作！',
    isCheckUnbindWireless: '是否解除无线呼叫器绑定？',
    remindWay: '报警设置',
    customContent: '自定义内容',
    residualFluidVolume: '剩余液量',
    editContent: '提示内容',
    tipsTime: '提醒时间',
    enterTipsTime: '请输入提醒时间',
    remindConditions: '提醒条件',
    closeRemind: '关闭提醒',
    selectRemindTime: '请选择提醒时间',
    enterMargin: '请输入余量',
    closeRemindSuccess: '关闭提醒成功',
    closeRemindText: '是否关闭当前备忘提醒的内容？',
    medicalInfo: '用药信息',
    criticalCare: '特级护理',
    docAdvice: '医嘱号',
    drugNames: '药品名称',
    usage: '用法',
    HZ: '频率',
    ST: '皮试',
    openToldTime: '开嘱时间',
    executionTime: '执行时间',
    collator: '核对人',
    executor: '执行人',
    infuDtails: '输液详情',
    infuDetector: '输液终端',
    deviceNum: '设备ID',
    BindBedNo: '绑定床号',
    tareWeightSet: '皮重设置',
    spec: '规格',
    selectSpec: '请选择规格',
    nominalCap: '标准容量',
    selectNominalCap: '请选择容量',
    tare: '皮重',
    thresholdManage: '阈值管理',
    dripNum: '点滴系数',
    inputDripNum: '请输入点滴系数',
    selectDripNum: '请选择点滴系数',
    inputDripText: '每毫升溶液的滴数为点滴系数',
    dripSpeed: '输液滴速',
    enterMinDrip: '请输入最小滴速',
    until: '至',
    enterMaxDrip: '请输入最大滴速',
    dropAndmin: '滴 / 分',
    otherSet: '其他设置',
    timeRemain: '剩余时间',
    inputTimeRemain: '请输入剩余时间',
    liquidRatio: '剩余液量占比',
    inputLiquidRatio: '请输入剩余液量占比',
    selectUnit: '请选择单位',
    ReportInter: '上报间隔',
    TerminalInter: '终端数据上报间隔',
    enterMinNum: '请输入最小数',
    selectFrequency: '请选择频次',
    fiveSecondsFitSug: '建议',
    fiveSecondsFitApr: '为宜',
    record: '注: 建议以默认时间为准，如若需自定义建议咨询管理员',
    typeSet: '类型设置',
    oneself: '自己',
    thirdParty: '第三方',
    selectReminder: '请选择提醒方式',
    inputMinRate: '请输入滴速最小值',
    inputMaxRate: '请输入滴速最大值',
    inputUnit: '请输入单位',
    inputMinNum: '请输入最小数值',
    removeSet: '确定删除此设置？',
    dropText: '最大滴速不能小于或等于最小滴速',
    pleaseSupplement: '请补充输液滴速的起始范围',
    inputNominalCap: '请输入标准容量',
    inputTareWeight: '请输入皮重重量',
    enterTareMax: '请输入皮重最大值',
    enterTareMin: '请输入皮重最小值',
    enterRemarks: '请输入备注，50字内',
    content50Char: '内容已经超过50个字符',
    content16Char: '内容已经超过16个字符',
    enterCapacity: '请输入容量',
    enterTareWeight: '请输入皮重',
    enterMinValue: '请输入最小值',
    enterMaxValue: '请输入最大值',
    terminalExport: '输液管理-终端设备导出',
    terminalImport: '输液终端批量导入',
    wirelessImport: '无线呼叫器批量导入',
    bedBatchImport: '床位批量导入',
    terminalBindingStatus: '输液终端状态',
    wirelessBindingStatus: '无线呼叫器状态',
    delFailedText: '对不起，删除失败!',
    bindDeviceText: '当前床位存在绑定设备，请解绑后再重新操作',
    selectInfoTerminal: '请选择输液终端',
    selectInfoWireless: '请选择无线呼叫器',
    addinfuChannel: '添加输液通道',
    deviceBallast: '设备空载',
    pleaseBindTerminal: '请分配输液终端',
    lightOff: '空载关机',
    ReportInterTipM: '提交失败，录入内容需不低于2分钟',
    ReportInterTipS: '提交失败，录入内容需不低于120秒',
    setAddFailTip: '提交失败，最小值不可大于最大值，请修改后重试',
    setAddFailTipT: '提交失败，最大值不可小于皮重，请修改后重试',
    positive: '阳性',
    feminine: '阴性',
    dropMinute: '滴/分',
    reminderMethod: '提醒方式',
    countDrops: '计算滴速',
    enterDrops: '请输入计算滴速',
    enterRange: '可输入的数值范围',
    PreVerification: '预分配校验',
    VerificationFailure: '校验失败可临时使用',
    VerificationFailure1: '校验失败不可临时使用',
    ThirdPartyCallers: '第三方呼叫器',
    DropRateDisplay: '滴速显示',
    OnlyEntered: '只能输入30-60之间的正整数',
    OnlyEntered1: '只能输入1-25之间的正整数',
    ToBeInfused: '待输液',
    treatmentCount: '病区处理项目统计',
    individualCount: '个人处理项目统计',
    selectPerson: '请选择具体人员',
    CurrentInfusion: '当前输液人数',
    todayInfusion: '今日输液人数',
    todayAlarm: '今日报警次数',
    todayCall: '今日呼叫次数',
    currentTerminalPow: '当前输液终端低电量数',
    currentWirelessPow: '当前无线呼叫器低电量数',
    AlarmTypeCount: '报警类型占比统计',
    infusionEndTypeCount: '输液结束类型占比统计',
    AlarmProcessingTimes: '报警处理次数',
    callHelpHandlingTime: '呼叫求助处理次数',
    infusionOperaHandlingTime: '输液操作处理次数',
    AlarmProcessingTimesShow: '报警处理(次数)',
    callHelpHandlingTimeShow: '呼叫处理(次数)',
    infusionOperaHandlingTimeShow: '输液操作(次数)',
    frequency: '次数',
    CallForHelp: '呼叫求助报警',
    speedAlarm: '滴速异常报警',
    MemoAlarm: '备忘录报警',
    infuBlockageAlarm: '输液堵塞',
    fastSpeedAlarm: '滴速过快报警',
    slowSpeedAlarm: '滴速过慢报警',
    PDAEnd: 'PDA结束输液关机',
    btnShowdown: '物理按钮关机',
    AutoShutdown: '自动关机',
    BuiltInLogo: '内置标识',
    CustomIdentity: '自定义标识',
    enterRFID: '请输入内置的无源RFID标签ID',
    enterNum: '请输入编号',
    sixLength: '长度在 1 到 6 个字符',
    fiftyLength: '长度在 1 到 50 个字符',
    CustomNumber: '自定义编号',
    DropRate: '滴速',
    bedPosition: '床位位置',
    closeAdvance: '收起高级搜索',
    Advance: '高级搜索',
    ManualConfirmation: '人工确认',
    ManualProcessing: '人工处理',
    SystemValidation: '系统确认',
    SystemProcessing: '系统处理',
    infusionParameterReminder: '输液参数提醒',
    patientsNameShow: '病患姓名显示',
    desensitization: '脱敏',
    Nodesensitization: '无需脱敏',
    drugSearch: '药品搜索',
    capacityAdjust: '容量调整',
    operationTime: '操作时间',
    operator: '操作人',
    operationWard: '操作病区',
    infuStopValue: '输液堵塞灵敏度',
    infuFinishValue: '输液完成灵敏度',
    high: '高',
    middle: '中',
    low: '低',
    alarmConfig: '报警管理',
    internalConfiguration: '内部配置',
    noLoadAbnormal: '空载异常'
  },
  // 病患管理
  care: {
    sendHelp: '发出一键求助',
    wristbandRemoved: '佩戴的手环被拆除',
    CancelHelp: '取消一键求助',
    Explain: '处理说明',
    statisticalMonth: '统计月份',
    chooseYM: '选择年月',
    patientPopulation: '病患人数',
    people: '人',
    Nostatistical: '暂无统计数据',
    SummaryFigures: '人数汇总',
    searchName: '请输入姓名搜索',
    checkOut: '临时签出',
    signOut: '签出',
    signIn: '签入',
    checkIn: '已签入',
    outTime: '签出时间',
    inTime: '签入时间',
    outOperator: '签出操作人',
    inOperator: '签入操作人',
    locateFailureTips: '定位失败！该资产未绑定蓝牙定位标签，无法定位',
    locateFailureTips2: '定位失败！请检查是否设备异常或网络断开！',
    patientsName: '病患姓名',
    cancelTrack: '取消追踪',
    realtimeTrack: '实时追踪',
    historicalTrack: '历史轨迹',
    queryTrajectory: '查询轨迹',
    TrajectoryQuery: '轨迹查询',
    trackList: '轨迹列表',
    showTrajectories: '显示轨迹',
    enterPatientName: '请输入病患姓名',
    checkTrajectoryTips: '查询的轨迹可能包含其他患病轨迹，该病患的历史轨迹应在',
    timeRange: '时间范围内',
    bracelet: '手环',
    location: '定位',
    braceletSet: '手环设置',
    record: '记录',
    heartRate: '心率',
    willPatient: '将病患',
    inHosRecords: '住院记录',
    hosSerialNum: '住院流水号',
    elapsedTime: '经过时间',
    operationalContext: '操作内容',
    fornowTips: '暂时没有操作记录',
    admittedHos: '入院',
    leaveHos: '出院',
    changeMajor: '转科',
    penName: '围栏名称',
    intoAlarm: '进入报警',
    departure: '离开报警',
    checkFencePosition: '查看围栏位置',
    enterBraceletLabel: '请输入手环标签',
    selectBraceletType: '请选择手环类型',
    alarmFence: '报警围栏',
    unbindBracelet: '解绑手环',
    selectBraceletLabel: '请选择手环标签',
    fenceAlarm: '围栏报警',
    oneKeyHelp: '一键求助',
    braceletWasRemoved: '佩戴的手环被拆除',
    callForHelp: '发出一键求助',
    cancelForHelp: '取消一键求助',
    crossBorder: '越界',
    retention: '滞留',
    levelFence: '围栏等级',
    levelSet: '等级设置',
    fenceLevelSet: '围栏等级设置',
    ecgPasteID: '心电贴ID',
    bloodOxygenClampID: '血氧夹ID',
    temperaturePasteID: '体温贴ID',
    temperatureBoxID: '体温盒ID',
    divideBed: '分配床位',
    useBed: '使用床位',
    tSelectKeyword: '请输入体温贴/体温盒id进行搜索',
    bSelectKeyword: '请输入血氧夹id进行搜索',
    eSelectKeyword: '请输入心电贴id进行搜索'
  },
  // 资产管理
  assets: {
    enterChannels: '请输入渠道',
    fillMeasurement: '请填写计量单位',
    enterSNnum: '请输入资产SN号',
    houseEntities: '区院',
    back: '返回',
    PreviousStep: '上一步',
    NextStep: '下一步',
    AssetCode: '资产编码',
    WardManage: '管理病区',
    deptManage: '管理部门',
    print: '打印',
    StorageTime: '录入时间',
    ResidualRate: '残值率',
    model: '型号',
    BasicMessage: '基本信息',
    SecurityTime: '过保时间',
    PleaseSelectManagementWard: '请选择管理病区',
    AssetSNCode: '资产SN号',
    AssetName: '资产名称',
    user: '使用人',
    PleaseInputUser: '请填写使用人',
    StorLocation: '存放位置',
    DistrictCourt: '请选择区院',
    Loudong: '请选择楼栋',
    LoudongIn: '请输入楼栋',
    storey: '请选择楼层',
    userTime: '使用期限',
    Month: '月',
    UnitMeasure: '计量单位',
    source: '来源',
    SelectSource: '请选择来源',
    Classification: '资产分类',
    PurchaseTime: '购入时间',
    money: '金额',
    brand: '品牌',
    PleaseSelectBrand: '请选择品牌',
    WarrantyStart: '保修起始',
    channel: '渠道',
    ExtendedInformation: '扩展信息',
    configuration: '配置',
    Additional: '额外配置',
    save: '保存',
    yuan: '元',
    Code: '编码',
    Name: '名称',
    classification: '分类',
    SelectCategory: '请选择分类',
    MapMode: '地图模式',
    PrintLabel: '打印标签',
    ImportAssets: '导入资产',
    AssetsInstock: '资产入库',
    Label: '标签',
    BoundLabel: '已绑定标签',
    more: '更多',
    borrow: '借用',
    return: '退还',
    maintain: '维修',
    MaintenanceCompleted: '维修完成',
    scrap: '报废',
    lose: '遗失',
    AssetInformation: '资产信息',
    OperationRecords: '操作记录',
    LocationRecords: '位置记录',
    ElectronicSignature: '电子签收',
    LoanOfAssets: '资产借用',
    BorrowWard: '借用病区',
    ChooseAreaToUse: '请选择借用病区',
    BorrowTime: '借用时间',
    BorrowUser: '借用人',
    EnterTheBorrower: '请输入借用人',
    LabelBinding: '标签绑定',
    Binded: '已绑定',
    LabelType: '标签类型',
    BluetoothTab: '蓝牙标签',
    SelectTab: '请选择标签',
    NormalLabel: '普通标签',
    SelectTabType: '请选择标签类型',
    AlarmFence: '报警围栏',
    CategoryOfAssets: '资产类别',
    UseOfTheWard: '使用病区',
    IntoTheLibrary: '入库人',
    Modifier: '修改人',
    ModifyTime: '修改时间',
    Months: '个月',
    SNNumber: 'SN号',
    AssetsLocation: '资产位置',
    ViewMapLocation: '查看地图位置',
    time: '时间',
    location: '位置',
    NoLocationInformationYet: '暂时没有位置信息',
    ImportFromTemplate: '从模板中导入',
    SequenceNumber: '序号',
    NameOfFence: '围栏名称',
    GetIntoTheAlarm: '进入报警',
    LeaveThePolice: '离开报警',
    CheckTheFence: '查看围栏位置',
    Operator: '操作人',
    ApplyArea: '申领病区',
    Claimant: '申领人',
    OperationContent: '操作内容',
    NoOperationalRecord: '暂时没有操作记录',
    ImportInTemplate: '模版里导入',
    TemplateName: '模版名称',
    leadingIn: '导入',
    property: '资产',
    ViewDetails: '查看详情',
    SelectBorrowingTime: '请选择借用时间',
    EnterAssetCode: '请输入资产编码',
    CodeTips: '资产编码不能超过 20 个字符',
    NameTips: '资产名称不能超过 20 个字符',
    AssetClassification: '请选择资产分类',
    SelectManagement: '选择管理科室',
    SelectStoragTime: '请选择入库时间',
    SelectPurchaseTime: '请选择购入时间',
    ModelTips: '型号不能超过 20 个字符',
    UnitTips: '计量单位不能超过 10 个字符',
    ChannelTips: '渠道不能超过 50 个字符',
    AssetSNNTips: '资产SN号不能超过 50 个字符',
    ConfigureTips: '备注不能超过 200 个字符',
    AdditionalTips: '额外配置不能超过 200 个字符',
    ApplyTime: '申领时间',
    Download: '下载签收单',
    ChangeApplicant: '变更申领人',
    OriginalC: '原申领人',
    NewApplicant: '新申领人',
    ConfirmDownload: '确认下载',
    EditSuccess: '编辑成功',
    DownloadPre: '下载预览',
    FormatTips: '金额格式不正确',
    EnterAmount: '请输入金额',
    FillInteger: '请填写整数',
    LessThanZ: '不能小于0',
    FillUseLife: '请填写使用期限',
    AssetManage: '资产管理',
    EditAssets: '编辑资产',
    ErrorTips: '获取数据出错',
    UploadFormat: '上传头像图片只能是JPG、JPEG、PNG、GIF格式',
    UploadExceed: '上传头像图片大小不能超过 3MB!',
    AddOne: '最多添加一张图片',
    MissingID: '缺少资产ID',
    ErrorRequest: '请求出错',
    selectTab: '请选择需要打印的标签',
    limitTips: '无源RFID标签绑定数量已经达到上限',
    ReturnSucc: '退还成功',
    number: '编号',
    ImportSucc: '导入成功',
    floor: '楼',
    FenceIDEmpty: '围栏ID为空',
    EnterAssets: '请输入资产',
    search: '搜索',
    EnteBrand: '请输入品牌名称',
    alarmProcessing: '报警处理',
    enterAssets: '请输入资产',
    enterAssetsTips: '请输入资产编码或名称',
    assetsModel: '资产型号',
    totalAssets: '总资产',
    idleAssets: '空闲资产',
    borrowAssets: '借用资产',
    normalAssets: '正常资产',
    useAssets: '使用资产',
    maintenanceAssets: '维修资产',
    scrapAssets: '报废资产',
    loseAssets: '遗失资产',
    statusPercentage: '状态占比',
    endemicArea: '病区分布',
    timeDistribution: '时间分布统计年份',
    selectYear: '请选择年份',
    classifyStatistics: '暂无分类统计数据',
    statusStatistics: '暂无状态统计数据',
    areaStatistics: '暂无病区统计数据',
    timeStatistics: '暂无时间分布统计数据',
    leisure: '空闲',
    claimed: '已申领',
    apply: '申领',
    enterClassify: '请输入分类名搜索',
    importClassify: '导入分类',
    addClassify: '新增分类',
    classifyName: '分类名称',
    categoryParent: '上级分类',
    LengthTips: '长度在 1 到 50 个字符',
    ChooseFill: '请选择填写备注信息',
    LengthTips2: '长度在 0 到 50 个字符',
    AddSuccess: '添加成功',
    BrandName: '品牌名称',
    CategoryName: '分类名称',
    UpperClassify: '上级分类',
    SelectSCategory: '请选择上级分类',
    exampleT: '例如：台',
    EnteCategory: '请输入分类名称',
    FillmeasureInfo: '请填写计量单位信息',
    LengthTips3: '长度在 0 到 10 个字符',
    LengthTips4: '长度在 0 到 200 个字符',
    EnterToSearch: '请输入分类名搜索',
    ImportClassify: '导入分类',
    NewClassify: '新增分类',
    del: '刪除',
    EnterBrand: '请输入品牌名搜索',
    NewBrand: '新增品牌',
    OnlyPrint: '一次最多只能打印',
    tabs: '个标签',
    EndOfLife: '进行报废操作',
    LossOperate: '进行遗失操作',
    Return: '进行退还',
    IdleAssets: '空闲资产',
    BorrowAssets: '借用资产',
    NormalAssets: '正常资产',
    MaintenAssets: '维修资产',
    EndOfLifeAssets: '报废资产',
    LostAssets: '遗失资产',
    screen: '筛选',
    NoDisaggregated: '暂无分类统计数据',
    StatusRatio: '状态占比',
    TemporaryStatus: '暂无状态统计数据',
    Distribution: '病区分布',
    NoWardArea: '暂无病区统计数据',
    TimeDistribution: '时间分布统计年份',
    SelectYear: '请选择年份',
    NoTimeDistribution: '暂无时间分布统计数据',
    TaskName: '任务名称',
    SelectAssets: '选择资产',
    AssetsSelected: '已按条件选择资产',
    Numberselected: '已选资产数',
    SelectExecutor: '请选择执行人',
    TaskDescrip: '任务描述',
    Tip: '提示：普通盘点只针对有RFID标签的资产。',
    chooseAssets: '请按资产选择或按条件选择资产',
    EnterTask: '请输入任务名称',
    selectAssets: '请选择资产',
    LengthTips5: '长度在 200 个字符内',
    InventAssets: '资产盘点',
    EditTasks: '编辑资产盘点任务',
    AdditionalTask: '新增资产盘点任务',
    Tip2: '提示：自动盘点只针对所有蓝牙标签的资产。',
    ImplementInvent: '执行盘点',
    LengthTips6: '长度在 50 个字符内',
    InventSucc: '盘点成功',
    result: '结果',
    SelectResults: '请选择盘点结果',
    Deficiencies: '盘亏',
    NotYetInvent: '尚未盘点',
    EnterAsset: '请输入资产名称搜索',
    InventResults: '盘点结果',
    NoCount: '未盘点',
    TotalTime: '共耗时',
    TaskType: '任务类型',
    AutoInvent: '自动盘点',
    ManualInvent: '手动盘点',
    TotalAssets: '任务总资产数',
    InventResult: '资产盘点结果',
    InventInfo: '盘点信息',
    InventDetails: '盘点明细',
    SelectByAssets: '按资产选择',
    allAssets: '全部资产',
    NumberSelected: '已选择资产数',
    ChoosebyC: '按条件选择',
    AllCategory: '全部分类',
    selectCategory: '已选分类',
    NoDataSelect: '未选择数据',
    SelectTabTab: '请选择标签标签',
    NotStart: '未开始',
    InProgress: '进行中',
    Finished: '已结束',
    NewInventTask: '新建盘点任务',
    InventoryNum: '盘点单号',
    WaitStart: '等待自动盘点开始',
    WaitCountResults: '等待自动盘点结果',
    WaitingResults: '等待结果',
    ExportD: '导出明细',
    ViewResults: '查看结果',
    ResultOfInvent: '资产盘点-盘点结果-',
    BorrowSucc: '借用成功',
    SavedAssets: '已保存的资产',
    SelectClassify: '--请选择上级分类--',
    enterAssetName: '请输入资产名称',
    fillNumber: '请填写型号',
    deliveryTime: '出库时间'

  },
  // 卒中
  emer: {
    EmerFoundationSetup: '基础设置',
    CategoryNameTips1: '分类名称不能为空',
    CategoryNameTips2: '分类名称不能超过20个字符',
    RemarksTips: '备注不能超过200个字符',
    LCstatisticalReports: '环节对比-统计报表-',
    Emer: '急诊',
    GodIn: '神内',
    GodOut: '神外',
    Intervene: '介入',
    LinkCompare: '环节对比',
    NoTreatOption: '没有选择治疗环节',
    NoStatisticsYet: '暂时没有统计数据',
    MeanTimeOfAve: '平均到达时间',
    WhereTimeOut: '其中超时',
    Examples: '例',
    Percentage: '占比',
    Standards: '标准',
    DTStoStroke: 'DTS入门至卒中小组会诊医生',
    TimeDTCAndCt: 'DTC入门至完成CT检查时间',
    TimeDNTAndAdmin: 'DNT入门至溶栓给药时间',
    TimeOttAndTreat: 'OTT发病至治疗时间',
    OPTtoFemoral: 'OPT入门至股动脉穿刺时间',
    DRTtoRepass: '再通后DRT入门至再通时间',
    TimeRange: '时间范围',
    SelectTimeRange: '请选择时间范围',
    StartDate: '开始日期',
    EndDate: '结束日期',
    Hours: '小时',
    bindChestCard: '绑定胸卡',
    pleaseInputMac: '请输入MAC地址',
    macAddress: 'MAC地址',
    macTips: 'MAC地址不能为空',
    macTips2: 'MAC地址只能包含英文字母和数字',
    realTimePatient: '实时病患',
    minuteAutomaticRefresh: '分钟自动刷新',
    autoCreatePatients: '自动创建病患',
    addPatient: '添加病患',
    patientsInfo: '患者信息',
    clearLink: '清空环节',
    endLink: '结束环节',
    completed: '已完成',
    stayTime: '停留时长',
    entryTime: '进入时间',
    leaveTime: '离开时间',
    delLinkRecords: '删除该环节记录？',
    clearLinkRecords: '清空该患者所有环节记录？',
    enterSNnumber: '请输入SN号',
    medicalRecordNo: '病历号',
    emerClassify: '急诊分类',
    enterNameTips: '请输入10个字符以内的姓名',
    snNoEmpty: 'SN号不能为空',
    snNoTips: 'SN号只能包含英文字符、数字',
    snNoTips2: 'SN号不能超出12个字符',
    pleaseEnterNumber: '请输入数字',
    ageCannotZero: '年龄不能小于0',
    ageCannotMax: '年龄不能超过999',
    medicalNoTips: '病历号不能超过50个字符',
    medicalNoTips2: '备注不能超过200个字符',
    chooseToLeave: '选择离开时间并结束环节',
    waitingStar: '等待开始',
    chestCard: '胸卡',
    beginLink: '开始环节',
    nameOfLink: '填写环节名称',
    chooseFence: '选择围栏',
    midstLink: '中间环节',
    linkTips: '至少需要保留一个环节',
    fenceNotEmpty: '围栏不能为空',
    linkTips2: '环节名称不能为空',
    linkTips3: '环节名称不能超过20个字符',
    fencesTips: '围栏不能重复选择',
    exportTimeTable: '导出时间管理表',
    recordTime: '记录时间',
    timeSheet: '时间管理表',
    hisPatient: '历史病患',
    hospitalID: '门诊/住院 ID',
    emerDoctor: '急诊接诊医生',
    nerveDoctor: '神内会诊医生',
    neurDoctor: '神外会诊医生',
    InterDoctor: '介入组医生',
    disease: '发病',
    diseaseTime: '发病时间',
    comingToHospital: '来院方式',
    ourHos: '我院',
    outerHos: '外院',
    selfHos: '自行来院',
    inTheHos: '院内发病',
    toCourtDate: '来院日期',
    preHos: '院前',
    timeForHelp: '呼救时间',
    ambulanceTime: '出车时间',
    timeOfArrival: '到达现场 / 首次医疗接触时间',
    timeOfTransit: '离开现场/转运时间',
    clinicalReception: '接诊',
    timeOfArrivalAtGate: '到达大门时间',
    timeOfMedical: '首次医疗接触时间',
    actualPassTime: '实际开绿通时间',
    emerDoctorTime: '急诊医生接诊时间',
    apoplexyTime: '通知卒中会诊医生时间',
    arrivalTime: '到达时间',
    bloodTime: '抽血完成时间',
    bloodDeliveryTime: '通知送血时间',
    bloodDrawingReport: '抽血报告时间（快速血糖）',
    bloodDrawingReport2: '抽血报告时间（血常规）',
    adviseDeliveryTime: '通知运送时间',
    deliveryAdviseTime: '运送到达时间',
    sendingCTtime: '送CT时间',
    timeOfCTroom: '到达CT室时间',
    timeOfHead: '首份头颅CT开始时间',
    CTcompletionTime: 'CT完成时间',
    CTAcompletionTime: '完成CTA时间（必要时）',
    reportTime: '报告时间',
    consciousness: '意识',
    questions: '提问',
    instruction: '指令',
    stare: '凝视',
    view: '视野',
    facioplegia: '面瘫',
    upLeft: '左上',
    upRight: '右上',
    lowerLeft: '左下',
    lowerRight: '右下',
    freemasonry: '共济',
    feel: '感觉',
    aphasia: '失语',
    articulation: '构音',
    neglect: '忽视',
    totalPoints: '总分',
    treatment: '治疗方式',
    thrombolysis: '单纯静脉溶栓',
    thrombolyticDrug: '溶栓药',
    dateTreatment: '治疗日期',
    thrombectomy: '单纯动脉取栓',
    bridging: '桥接（溶栓+取栓）',
    chooseReason: '选择原因',
    intravenous: '静脉溶栓',
    otherReason: '其他原因',
    thrombolytic: '溶栓给药时间',
    punctureTime: '股动脉穿刺时间 / 造影开始时间',
    therapyTime: '血管内治疗开始时间',
    recanalization: '再通',
    recanalizationTime: '再通时间',
    patientsTo: '患者去向',
    centre: '中心',
    neurologyDepart: '神经内科',
    neurosurgeryDepart: '神经外科',
    viewHos: '出观离院',
    aroundEmer: '绕行急诊',
    emerDeath: '急诊死亡',
    hospitalization: '住院治疗',
    carotidEndarterectomy: '颈动脉内膜剥脱术',
    carotidArteryStenting: '颈动脉支架成形术',
    hematoma: '去骨瓣开颅血肿清除术',
    aspiration: '立体定向椎血肿抽吸术',
    outcomePatients: '患者转归',
    significantComplication: '严重并发症',
    symptomaticHemorrhage: '症状性出血',
    die: '死亡',
    selectTreatmentTime: '请选择治疗时间',
    SequenceNumberTip: '序号必须为数值',
    SequenceNumberExceed: '序号不能超过10位',
    othersTips: '其他不能超过100个字符',
    scoreNumberTips: '评分只能为0到10的整数',
    ToHospital: '到院',
    ChooseTreatment: '请选择治疗方式',
    AllOfThem: '全选',
    EmptyIt: '清空',
    ReverseElection: '反选',
    TherapeuticSession: '治疗环节',
    ChooseTreat: '请选择治疗环节',
    ListNames: '名单列表',
    BatchExport: '批量导出',
    ContactNum: '联系电话',
    doc: '医生',
    IntroductFirstContact: '入门-首次接触',
    FirstContactgoGreen: '首次接触-开通绿通',
    FirstContactTEMT: '首次接触-急诊医生接诊',
    FirstContactCallDoc: '首次接触-通知卒中医生',
    EMTComInCallstrokeDoc: '急诊医生接诊-通知卒中医生',
    PageDocConsultantIsHere: '通知卒中医生-会诊医生到达',
    BloodDrawSugarReport: '抽血-血糖报告',
    BloodDRAWCBCReport: '抽血-血常规报告',
    FirstContactNotify: '首次接触-通知运送',
    OpenGreenNotify: '开通绿通-通知运送',
    EmtsNotify: '急诊医生接诊-通知运送',
    ConsultantNotify: '会诊医生到达-通知运送',
    NotifyArriving: '通知运送-运送到达',
    TransportHereCT: '运送到达-送CT',
    CTCTroom: '送CT-到达CT室',
    ComeUpStartCT: '到达CT-开始CT',
    StartCTClearCT: '开始CT-完成CT',
    CTReportComplete: '完成CT-CT报告',
    TotalNumPatient: '病患总人数',
    NumOfMale: '男性病患人数',
    ProportionOfMale: '男性病患占比',
    NumOfFemale: '女性病患人数',
    ProportionOfFemale: '女性病患占比',
    UnknownSex: '未知性别人数',
    UnknownSexRatio: '未知性别占比',
    CurrentCountTime: '当前统计时间',
    Year: '年份',
    Month: '月份',
    CustomTimeFilter: '自定义时间筛选',
    AgeShare: '年龄段占比',
    AveLinkLength: '环节平均时长',
    Under30: '30岁以下',
    to50: '31岁~50岁',
    to70: '51岁~70岁',
    Over71: '71岁以上',
    UnknownAgeRange: '未知年龄段',
    UnknownEmerCategory: '未知急诊分类',
    DateChoseIncorrect: '选择的日期有误',
    Birthday: '出生日期',
    HospitalNum: '住院号',
    OutpatientNumber: '门诊号',
    IDNumber: '身份证号',
    Nation: '民族',
    HospitalWay: '入院途径',
    ChestCardID: '胸卡ID',
    Saved: '已保存',
    Submitted: '已提交',
    HasBeenReported: '已上报',
    Diagnose: '诊断',
    DataExport: '数据导出',
    PatientQualityControlForm: '病患质控表',
    DoctorQualityControlForm: '医生质控表',
    NIHSSGrade: 'NIHSS 评分',
    TreatmentOfType: '治疗类型',
    DTS: 'DTS',
    DTC: 'DTC',
    ONT: 'ONT',
    DNT: 'DNT',
    OPT: 'OPT',
    DPT: 'DPT',
    DRT: 'DRT',
    ThePercentageFilledIn: '已填占比',
    LastUpdateTime: '最后更新时间',
    TimerShaft: '时间轴',
    TimeTable: '管理表',
    FirstAidMessage: '急救信息',
    ConsultationMessage: '会诊信息',
    Thrombolysis: '溶栓',
    ApoplexyDoctor: '卒中会诊医生',
    IntravenousDoctor: '静脉溶栓医生',
    InterveneDoctor: '介入医生',
    TimeOfFindingSymptoms: '发现症状开始时间',
    LastNormalTime: '最后正常时间',
    WhetherToHaveAStrokeAfterWakingUp: '是否醒后卒中',
    TimeOfCerebralInfarction: '脑梗发病时间',
    Known: '已知',
    WakeUpStroke: '醒后卒中',
    Ambulance: '120出车',
    GoToTheHospitalByYourself: '自行来院',
    GetSickInTheHospital: '院内发病',
    FromOtherHospitals: '外院转诊',
    FromWhichHospital: '转诊医院',
    BeforeArrivingAtTheHospital: '院前',
    CallTime: '呼叫时间',
    DepartureTime: '发车时间',
    FirstTreatmentTime: '首次医疗接触时间',
    ArriveAtTheScene: '到达现场',
    SelfDescriptionAndSymptom: '主诉及症状',
    ComprehensiveAssessment: '综合评估',
    Sober: '清醒',
    Dim: '模糊',
    Delirium: '谵妄',
    Drowsiness: '嗜睡',
    Lethargy: '昏睡',
    LightComa: '浅昏迷',
    ModerateComa: '中昏迷',
    DeepComa: '深昏迷',
    BloodPressure: '血压',
    SystolicPressure: '收缩压',
    DiastolicPressure: '舒张压',
    Breathe: '呼吸',
    time: '次',
    BloodOxygenSaturation: '血氧饱和度',
    Temperature: '体温',
    NotificationTime: '通知会诊医生时间',
    DoctorArrivalTime: '会诊医生到达时间',
    BloodTransportTime: '运送收血时间',
    BloodCollectionTime: '抽血接收时间',
    BloodGlucoseReportTime: '血糖报告时间',
    BloodRegularReportingTime: '血常规报告时间',
    Examine: '检查',
    LaboratoryExamination: '实验室检查',
    FastBloodSugar: '快速血糖',
    WhiteBloodCellCount: '白细胞计数',
    Hemoglobin: '血红蛋白',
    BloodPlatelet: '血小板',
    INR: 'INR值',
    PT: 'PT值',
    APPT: 'APPT值',
    SerumCreatinine: '血肌酐',
    Phosphocreatine: '肌酸磷酸',
    PhosphocreatineIsoenzyme: '肌酸磷酸同工酶',
    Electrocardiogram: '心电图检查',
    NormalSinusRhythm: '正常窦率',
    AtrialFibrillation: '房颤',
    Flutter: '房扑',
    Arrhythmia: '其他心律正常',
    TentativeDiagnosis: '初步诊断',
    CerebralIschemicStroke: '脑梗塞',
    TIA: 'TIA',
    CerebralHemorrhage: '脑出血',
    SubarachnoidHemorrhage: '蛛网膜下腔出血',
    FailedToClassify: '其他未能分类卒中',
    OtherDiseases: '其它疾病',
    Aneurysm: '是否动脉瘤',
    CT: 'CT',
    CTA: 'CTA',
    CTAcomplete: '是否完成CTA',
    NECTmessage: 'NECT评价信息',
    HMCAS: '大脑中动脉高密度征',
    HICAS: '颈动脉高密度征',
    HBAS: '基底动脉高密度征',
    InsularRibbonSign: '岛带征',
    ACMCI: '前循环大面积脑梗死早期征象',
    PosteriorCirculationInfarction: '后循环脑梗死',
    ASPECT: 'ASPECT评分',
    SubcorticalStructuralArea: '皮层下结构区域',
    CaudateNucleus: '尾状核',
    LentiformNucleus: '豆状核',
    Capsule: '内囊',
    InsularCortex: '岛叶皮质',
    StandardForEvaluation: '评分标准',
    PastHistory: '既往史',
    AllergyHistory: '过敏史',
    MedicationHistory: '用药史',
    And: '及',
    Hypertension: '高血压',
    Diabetes: '糖尿病',
    CoronaryHeartDisease: '冠心病',
    CerebralInfarction: '脑梗死',
    Alimentarytracthemorrhage: '消化道出血',
    MajorSurgery: '大手术',
    HeadTrauma: '头颅外伤',
    HistoryOfCancer: '肿瘤病史',
    OtherMedicalHistory: '其它病史',
    RecentMedicationHistory: '近期用药史',
    Aspirin: '阿司匹林',
    Clopidogrel: '氯吡格雷',
    Warfarin: '华法林',
    Dabigatran: '达比加群',
    Rivaroxaban: '利伐沙班',
    mRSGrade: 'mRS 评分',
    GCSGrade: 'GCS 评分',
    ConsultationResults: '会诊结果',
    SwallowingFunctionGrade: '吞咽功能评分',
    IntravenousThrombolysisRecord: '静脉溶栓记录',
    Height: '身高',
    Weight: '体重',
    BMI: 'BMI',
    DrugDose: '药物剂量',
    BleedingGums: '牙龈出血',
    EndovascularTreatmentRecord: '血管内治疗记录',
    LocalAmbulance: '本地救护车',
    EmergencyDepartmentOfOurHospital: '本院急诊科',
    SurgicalComplications: '手术并发症',
    PatientTrace: '患者去向',
    ICU: '中心ICU',
    Surgery: '外科手术',
    NoAssessment: '未评',
    MRI: 'MRI',
    CompletionTime: '完成时间',
    Reason: '原因',
    Submission: '提交',
    ThresholdSetting: '阈值设置',
    FenceSetting: '围栏设置',
    Nurse: '卒中护士',
    TreatmentTime: '治疗时间',
    Puncture: '穿刺',
    care: '病患管理',
    DiseaseTable: '病患质控表',
    DoctorControlTable: '医生质控表',
    Treatment: '治疗',
    AddPatient: '新增病患',
    ConsultationScheduling: '会诊排班',
    TrainScheduling: '出车排班',
    ReportDataExport: '导出上报数据',
    TimeSet: '时段设置'
  },
  // 急诊胸痛
  pectoralgia: {
    ThresholdSetting: '阈值设置',
    S2FMC: 'S2FMC',
    FMC2ECG: 'FMC2ECG',
    D2W: 'D2W',
    FMC2W: 'FMC2W',
    D2B: 'D2B',
    JGDB: '肌钙蛋白报告时间',
    DGSJH: '导管室激活时间',
    XDT: '心电图报告时间'
  },
  // 设备管理
  equip: {
    newBracelet: '新增手环',
    newLabel: '新增标签',
    tagID: '标签ID',
    informationView: '信息查看',
    assetID: '请输入资产标签ID',
    selectDevice: '请选择设备类型',
    insufficient: '电量不足',
    sufficient: '电量充足',
    enterID: '请输入标签ID',
    limitLabel: '标签ID不能超过50个字符',
    SelectBind: '请选择绑定',
    DelLabel: '是否删除此标签',
    FenceAlarmType: '围栏报警类型',
    AssociatFenceArea: '关联围栏区域',
    StartDraw: '开始画',
    EndDraw: '结束画',
    EnterFenceName: '请输入围栏名称',
    to16: '长度在 3 到 16 个字符',
    SelectAlarmType: '请选择报警类型',
    to120: '长度在 2 到 120 个字符',
    StartPaintFence: '请开始画围栏',
    DotsTips: '并且画的点至少为3个',
    formWrongTips: '表单内容有误，请检查后再提交',
    NewRW: '新增读写器',
    EquipmentNum: '设备编号',
    IpAddress: 'IP地址',
    OnlineState: '在线状态',
    TriggerSortEvent: '触发了排序事件',
    XAxisCoordinates: 'X轴坐标',
    YAxisCoordinates: 'Y轴坐标',
    IpFormatErr: 'IP格式错误',
    IPnotEmpty: 'IP不能为空',
    EnterDeviceName: '请输入设备名称',
    EnterDeviceNum: '请输入设备编号',
    LackOf: '缺少',
    SelectCoordinates: '请选取坐标',
    DeviceAddr: '设备地址',
    SignalRepeater: '信号中继器',
    SignalReceiver: '信号接收器',
    ExitCamera: '出口监视器',
    ToWard: '所属病区',
    EquipmentStatus: '设备状态',
    Abnormity: '异常',
    EquipmentType: '设备型号',
    FirmwareVersion: '固件版本',
    DeviceIpAddr: '设备IP地址',
    MacAddress: 'MAC地址',
    OperationMode: '工作方式',
    WorkPort: '工作端口',
    TargetIP1: '目标IP1',
    PortIP1: '端口IP1',
    TargetIP2: '目标IP2',
    PortIP2: '端口IP2',
    TargetIP3: '目标IP3',
    PortIP3: '端口IP3',
    TargetIP4: '目标IP4',
    PortIP4: '端口IP4',
    SubnetMask: '子网掩码',
    GatewayIp: '网关IP',
    HowGetIP: '获取IP方式',
    StaticAcquisition: '静态获取',
    DynamicAcquisition: '动态获取',
    WebPort: '网页端口',
    CommandPort: '命令端口',
    SelectCoordinate: '选取坐标',
    EnterDeviceAddr: '请输入设备地址',
    ModifyButton: '修改按钮',
    NewEquipment: '新增设备',
    AddTime: '添加时间',
    LastActiveTime: '最后活跃时间',
    port: '端口',
    ParameterSet: '参数设置',
    delDeviceOrNot: '是否删除此设备?',
    EnterNameSearch: '请输入名称搜索',
    AddFence: '新增围栏',
    HoldOverAlarm: '滞留报警',
    EnterTplNameSearch: '请输入模板名搜索',
    NewTemplate: '新增模板',
    TplName: '模板名称',
    EnterAlarmFenceTplname: '请输入报警围栏模版名称',
    to20: '长度在 3 到 20 个字符',
    To120: '长度在 0 到 120 个字符',
    NewType: '新增类型',
    DevTypeName: '设备类型名称',
    Business: '所属业务',
    TechnologyType: '技术类型',
    SelectTecType: '请选择技术类型',
    SelectBusiness: '请选择所属业务',
    EnterDevTypeName: '请输入设备类型名称',
    DevTypeNameTips: '设备类型名称不能超过20个字符',
    Obstetrics: '产科'

  },

  // 系统设置
  sys: {
    NumberOfAssociatedDevices: '关联设备数量',
    AffiliatedHospital: '所属院区',
    enterEmployeSearch: '请输入员工编号搜索',
    enterRoleSearch: '请输入角色名称搜索',
    batchRemove: '批量删除',
    roleName: '角色名称',
    proceed: '进行',
    confirmTo: '确定对',
    phoneNum: '手机号',
    employeeID: '员工编号',
    password: '密码',
    confirmPassword: '确认密码',
    customAccount: '自定义账号',
    email: '邮箱',
    depart: '所属部门',
    clickSelectDepartment: '点击选择部门',
    respectiveWard: '所属病区',
    clickSelectWard: '点击选择应用范围',
    role: '角色',
    passTips: '密码不能为空',
    confirmPasswordTips: '确认密码不能为空',
    pwdTips: '确认密码与密码输入不一致',
    emailTips: '邮箱格式错误',
    phoneNumTips: '手机号格式错误',
    wardTips: '病区不能为空',
    nameTips: '姓名不能为空',
    pwdText: '长度为 8 到 16 个字符',
    pwdTipsText: '长度为 8 到 16 个字符，至少存在一个字母，其他可以是任意字符',
    employeeTips: '员工编号不能为空',
    authorization: '授权',
    roleNameTips: '角色名称不能为空',
    departTips: '部门不能为空',
    Enquiry: '查询',
    ParameterName: '参数名',
    ParameterVal: '参数值',
    parameterNameTips: '参数名不能为空',
    parameterValTips: '参数值不能为空',
    IntoDepartment: '导入科室',
    DepartID: '科室ID',
    NameDepartment: '科室名称',
    CodingNameDepartment: '科室编码',
    SynchronizeData: '同步数据',
    SuperiorDepart: '上级科室',
    SORTnum: '排序号',
    ClickToSelectSupDepa: '点击选择上级科室',
    depaNameTips: '科室名称不能为空',
    SupDepaTips: '上级科室不能为空',
    FirstlevelDepaTips: '一级科室不能选择上级科室',
    DictionaryName: '字典名称',
    DictionaryType: '字典类型',
    DictionaryCode: '字典码',
    DictionaryVal: '字典值',
    SORT: '排序',
    DicNameTips: '字典名称不能为空',
    DicTypeTips: '字典类型不能为空',
    DicCodeTips: '字典码不能为空',
    DicValTips: '字典值不能为空',
    DefaultField: '预设字段',
    enterContents: '请输入内容',
    NewLocation: '新增位置',
    HptDistrict: '院区',
    Buildings: '楼栋',
    selectDistrict: '请选择院区',
    NewHplArea: '新增院区',
    EditorialOffice: '编辑院区',
    SuperiorUnit: '上级单位:',
    FillBuildingName: '请填写楼栋名称',
    buildingNameTips: '楼栋名称长度在 1 到 50 个字符内',
    institutionName: '院区名称:',
    enterHplName: '请输入院区名称',
    HplNameTips: '院区名称中不能带有空格',
    HplNameTips2: '院区名称长度在1-50字符内',
    UserNameAndAction: '用户名／用户操作',
    UserName: '用户名',
    UserOperation: '用户操作',
    RequestMethod: '请求方法',
    ReqParameter: '请求参数',
    ExecutionTime: '执行时长(毫秒)',
    SuperMenu: '上级菜单',
    ContentsTable: '目录',
    Menu: '菜单',
    btn: '按钮',
    MenuUrl: '菜单URL',
    AuthorizationSigns: '授权标识',
    clickSelectMenu: '点击选择上级菜单',
    MenuRoute: '菜单路由',
    MultipleCommas: '多个用逗号分隔',
    SuchAs: '如',
    menuURLtips: '菜单URL不能为空',
    menuNameTips: '菜单名称不能为空',
    parentMenuTips: '上级菜单不能为空',
    FenceCacheInfoSync: '围栏缓存信息同步',
    TagCacheInfoSync: '标签缓存信息同步',
    SYNCfailure: '同步失败',
    newPassword: '新密码',
    confirmNewPassword: '重复新密码',
    Common: '常用',
    resetPassword: '修改密码',
    oldPassword: '原密码',
    alarmSoundSetting: '报警声音设置',
    siteInfo: '位置信息'
  },
  job: {
    BatchSuspension: '批量暂停',
    BatchRecovery: '批量恢复',
    BatchExecution: '批量立即执行',
    LogList: '日志列表',
    MethodName: '方法名称',
    Parameters: '参数',
    Expression: '表达式',
    TimeOut: '暂停',
    Recover: '恢复',
    Immediately: '立即执行',
    userNameTips: '用户名不能为空',
    methodNameTips: '方法名称不能为空',
    cronExpressTips: 'cron表达式不能为空',
    TaskId: '任务ID',
    LogID: '日志ID',
    Failure: '失败',
    UnitTime: '耗时(单位: 毫秒)'
  },
  oss: {
    cloudConfig: '云存储配置',
    uploadFiles: '上传文件',
    operateNextTips: '操作成功, 是否继续操作?',
    imgTips: '只支持jpg、png、gif格式的图片！',
    dragTips: '将文件拖到此处，或',
    storageType: '存储类型',
    qiniu: '七牛',
    Aliyun: '阿里云',
    qcloud: '腾讯云',
    freeTips: '免费申请(七牛)10GB储存空间',
    domainName: '域名',
    boundDomainName: '绑定的域名',
    pathPrefix: '路径前缀',
    notSetTips: '不设置默认为空',
    spaceName: '空间名',
    storageSpaceName: '七牛存储空间名',
    regionOrigin: '所属地区',
    originTips: '如：sh（可选值 ，华南：gz 华北：tj 华东：sh）'
  },
  // 护理白板
  nurse: {
    remarkContent: '备注内容',
    createUserName: '创建人',
    creatSource: '创建来源',
    addRemark: '新增备忘',
    lengthTip100: '长度在 1 到 100 个字符',
    dictName: '字典名称',
    addDict: '新建数据字典',
    dictNew: '新建',
    dictCode: '字典编码',
    selectPrevDict: '点击选中上级字典',
    prevDict: '上级字典',
    SuperiorParameter: '上级参数',
    lengthTip20: '长度在 1 到 20 个字符',
    boardName: '白板名称',
    scope: '应用范围',
    source: '来源',
    boardId: '板块ID',
    Module: '所属模块',
    selectBoardId: '请选择板块ID',
    dictNamePlaceholder: '请输入字典名称',
    StartNursingScreen: '启动护理大屏',
    NewNursingWhiteboard: '新增护理白板',
    PleaseEnterWhiteboardName: '请输入白板名称',
    preview: '预览',
    ApplicationWard: '应用范围',
    HaveChosen: '已选择',
    SickWards: '个病区',
    LayoutStyle: '布局风格',
    ViewExamples: ' 查看示例',
    SetAsDefault: '设为默认',
    CancelDefault: '取消默认',
    delThis: '是否删除这项内容',
    startUp: '启动',
    RecycleBin: '回收箱',
    drafts: '草稿箱',
    Release: '发布',
    delete: '删除',
    edit: '编辑',
    DeleteTime: '删除时间',
    UndercutDeletion: '彻底删除',
    recovery: '恢复',
    ManualEntry: '手工录入',
    notice: '通知详情',
    setting: '设置',
    projectUpdateTime: '项目更新时间',
    systemdefault: '系统默认',
    secondDay: '次日',
    update: '更新',
    dataManagement: '数据管理',
    projectName: '项目名称',
    exportProject: '导出项目',
    projectMaintain: '项目维护',
    CareMatters: '护理事项',
    medicglance: '医护一览',
    patientView: '患者一览'

  },
  // 母婴
  baby: {
    unbundleTip: '“确定”操作解绑后才将该婴儿与该床位解绑。',
    outHosNotUnbound: '出院待解绑',
    BabyLabel: '婴儿标签',
    MotherTag: '母亲标签',
    Unused: '未使用',
    Inuse: '使用中',
    LabelCutAlarm: '标签剪断报警',
    ExitCameraAlert: '出口监视器报警',
    EquipAlarm: '设备报警',
    PairAlarm: '配对报警',
    CheckoutTips: '签出未归报警',
    LowBatAlarm: '低电量报警',
    MonBabyAlarm: '母婴配对报警',
    offlineAlarm: '离线报警',
    activateAlarm: '激活报警',
    bedOccupancyAlarm: '床位占用报警',
    Handler: '处理人',
    ChooseComPhrase: '选择常用语',
    Falsealarm: '报警误触，已检查无异常！',
    equipFailure: '设备失灵，已检查！',
    SetInspectItem: '巡查事项设置',
    babyName: '婴儿姓名',
    babySex: '婴儿性别',
    MotherName: '母亲姓名',
    MotherHplNum: '母亲住院号',
    SearchResults: '巡查结果',
    patrolTime: '巡查时间',
    InspectOne: '巡查人',
    AddInspectItems: '添加巡查事项',
    enterKeyword: '请输入关键字',
    signIO: '签入签出',
    MotherBabyPairs: '母婴配对',
    PatrolRecords: '巡查记录',
    calRecords: '离线记录',
    OperationRecord: '操作记录',
    AlarmRecord: '报警记录',
    OffWarning: '关闭警告音',
    TemporarilyOut: '临时迁出',
    Returnrest: '回归剩余',
    babyLabelTips: '婴儿标签电量过低',
    mom: '母亲',
    baby: '婴儿',
    BoundMomTag: '已绑定母亲腕带',
    WaitBabyInfo: '等待婴儿信息',
    inactive: '未激活',
    BoundBabyLabel: '已绑定婴儿标签',
    notBoundBabyLabel: '未绑定婴儿标签',
    BabyTagActivated: '已激活婴儿标签',
    BabyInfoAssigned: '已分配婴儿信息',
    PreAssignedLabel: '预分配婴儿标签',
    labelSet: '标签设置',
    history: '历史记录',
    PreBoundLabel: '预绑婴儿标签',
    BabyLocation: '婴儿位置',
    handleOperation: '请处理报警后操作',
    checkBabyIn: '请将该婴儿签入后操作',
    babyTagOffline: '婴儿标签处于离线状态',
    will: '将',
    ToCheckIn: '进行签入',
    CheckinS: '签入成功',
    ClearAlarmSucc: '清除声光报警成功',
    HavetoLabel: '已绑标签',
    UnbundLabel: '解绑标签',
    LabelBaby: '将婴儿标签',
    ForSolutionTo: '进行解绑',
    PairingSUCC: '配对成功',
    MatchFailure: '配对失败',
    MatchTime: '配对时间',
    operatingInfo: '操作信息',
    CompletePreBind: '完成预绑婴儿标签绑定',
    CompleteLabelBind: '完成婴儿标签绑定',
    OperationChange: '操作更换标签',
    OperationUnbind: '操作解绑标签',
    CheckOutOper: '操作签出',
    OperatingCheckin: '操作签入',
    ReceivePushMom: '接收推送母亲信息',
    ReceivePushBaby: '接收推送婴儿信息',
    ReceiveBedChange: '接收推送换床信息',
    ReceiveDischarge: '接收出院信息',
    OutHospitalUnbundleLabel: '操作出院解绑标签',
    operationTime: '操作时间',
    BabyLabelBind: '婴儿标签绑定',
    ReasonCheckOut: '签出事由',
    PlanRegressTime: '计划回归时间',
    ToCheckOut: '进行签出',
    After5min: '5分钟后',
    After10min: '10分钟后',
    afterHalfhour: '半小时后',
    afterAHour: '1小时后',
    afterTwoHour: '2小时后',
    afterThreeHour: '3小时后',
    seeADoc: '就诊',
    nurse: '护理',
    takeABath: '洗澡',
    FamiliesFrom: '家属带离',
    CheckOutSucc: '签出成功',
    babyNormal: '婴儿无异常',
    babyAbnormal: '婴儿有异常',
    locatorData: '定位数据',
    numberBabiesHpl: '在院婴儿数',
    Newbabiestoday: '今日新增婴儿数',
    NumDischargedT: '今天出院人数',
    CheckOutT: '今日签出数',
    CheckInT: '今日签入数',
    NumPatrolsT: '今日巡查数',
    TagAlarmNumT: '今天标签告警数',
    NumbMatAndInfaT: '今日母婴配对数',
    numboys: '男孩人数',
    numgirl: '女孩人数',
    AnUnknownNum: '未知人数',
    SexRatioOfInfants: '在院婴儿性别占比',
    LabUseStatisticalP: '标签使用统计占比',
    haveBeenUsed: '已使用',
    Taguse: '标签使用',
    TnfantManageAlarmRatioT: '今日婴儿管理报警占比',
    UnusedPairFailalarm: '未使用配对失败报警',
    ExportAlarm: '出口报警',
    TBbabylabAlarmRatio: '今日婴儿标签报警占比',
    labelcut: '标签剪断',
    EquipHisAlarmRatio: '设备历史报警占比',
    signalMonitor: '信号监视器',
    TWorkContentRatio: '今日工作内容占比',
    Babypatrol: '婴儿巡查',
    MoveOutof: '签入签出',
    distributionTime: '时间分布',
    pm: '时',
    No: '日',
    workloadStatistics: '工作量统计',
    chartInfo: '图表信息',
    BindBabyInfo: '绑定婴儿信息',
    ActivatedTips: '已激活，请及时确认完成婴儿绑定！',
    selectBedNum: '请选择要绑定的床号',
    selectBaby: '请选择要绑定的婴儿',
    LOOKTG: '返回上一页',
    enterRemarks: '请输入备注',
    BabyLabelsAssigned: '已分配婴儿标签',
    noBabyLocation: '无该婴儿定位信息',
    bindTagReminder: '绑定标签提醒',
    LabelUntyingReminder: '标签解绑提醒',
    bindBabyInTime: '请及时绑定婴儿',
    CheckOutNoReturn: '签出未归',
    NoBabyListNow: '暂时没有婴儿信息',
    NoBedListNow: '暂时没有床号信息',
    SyncBabyInformation: '同步婴儿信息',
    checkOutNoReturnAlarm: '签出未归报警',
    exitMonitorAlarm: '出口监视器报警',
    labelClippingAlarm: '标签剪断报警',
    babyTagOfflineAlarm: '婴儿标签离线报警'
  },
  // 温湿度
  humiture: {
    customNum: '自定义编号',
    MonitoringPoint: '监测点',
    addMonitoringPoint: '添加监测点',
    PleaseBindHumiture: '请绑定温湿度终端',
    delMonitoringPoint: '删除监测点',
    deviceManagement: '设备管理',
    MonitoringPointDeatil: '监测详情'
  },
  ...zhLocale
};

export default cn;
