import axios from '@/utils/request';

const PROXY = process.env.NODE_ENV === 'development' ? '/proxy' : '';

function Basic_Url (url) {
  let basic_url = '/api/pati';
  return basic_url + url;
}

const patiAPI = {
  // 床位设置
  // 获取床位设置列表
  getBedSetList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bedMattressBind/bedPage'), method: 'post', data }, option));
  },
  // 床位绑定床垫
  BedSetBindMattress (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bedMattressBind/add'), method: 'post', data }, option));
  },
  // 床位解绑床垫
  BedSetUnbindMattress (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bedMattressBind/delete'), method: 'post', data }, option));
  },
  // 床位更绑设备
  BedSetChangeBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bedMattressBind/update'), method: 'post', data }, option));
  },
  // 查询未绑定床垫列表
  getUnbindBedMattressList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + Basic_Url('/bedMattressBind/selectUnbindMattress'), method: 'post', data }, option));
  },
  // ----------------------------------------
  // 终端设置
  // 获取床垫报警列表信息
  getBedWarmingList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/mattressPage', method: 'post', data }, option));
  },
  // 新增床垫设备
  addBedEquipment (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/mattressBind', method: 'post', data }, option));
  },
  // 解绑床垫
  unbindMattress (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/delete', method: 'post', data }, option));
  },
  // 查询未绑定床位列表
  getUnbindBedMattressBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/selectUnbindBed', method: 'post', data }, option));
  },
  // ----------------------------
  // 报警记录
  // 报警记录分页查询
  getAlertList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/listPage', method: 'post', data }, option));
  },
  // 不分页查询报警记录-----床垫用
  getAlarmList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/list', method: 'post', data }, option));
  },
  // 查询报警记录详情
  getAlertDetails (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/detail', method: 'post', data }, option));
  },
  // 报警记录处理
  handleAlert (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/update', method: 'post', data }, option));
  },
  // 病患管理
  // 心律分布
  getHeartRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/heartRecord/list', method: 'post', data }, option));
  },
  // 查询病患列表--不分页
  getpatientList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patient/list', method: 'post', data }, option));
  },
  // 查询病患列表--分页
  getpatientListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patient/listPage', method: 'post', data }, option));
  },
  // 病患管理
  // 心律分布
  // 添加护理提醒
  addnursingReminder (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/nursingReminder/add', method: 'post', data }, option));
  },
  // 查看护理提醒详情
  detailnursingReminder (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/nursingReminder/detail', method: 'post', data }, option));
  },
  // 根据病患id获取预警设置列表
  getAlarmSettingsList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmSettings/list', method: 'post', data }, option));
  },
  // 获取预警详情
  detailalarmSettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmSettings/detail', method: 'post', data }, option));
  },

  // 添加修改预警提醒
  addOrEditalarmSettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmSettings/add', method: 'post', data }, option));
  },
  // 获取睡眠设置详情
  getsleepSettingsdetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/sleepSettings/detail', method: 'post', data }, option));
  },
  // 添加修改睡眠设置
  addOrEditsleepSettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/sleepSettings/add', method: 'post', data }, option));
  },
  // 查询未绑定的床垫页面
  getbedMattressBindselectUnbindMattress (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/selectUnbindMattress', method: 'post', data }, option));
  },
  // 床位绑定床垫,未绑定床垫调用这个接口
  addbedMattressBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/add', method: 'post', data }, option));
  },
  // 有床垫了调用这个接口  /api/pati/bedMattressBind/update
  havePatient (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/update', method: 'post', data }, option));
  },

  // 解绑床垫
  deletebedMattressBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/bedMattressBind/delete', method: 'post', data }, option));
  },

  // 分页查询个人报警记录--病患id(查询个人报警记录必填)
  getPersonalarmRecordlistPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/listPage', method: 'post', data }, option));
  },
  // 处理报警个人未处理
  handlealarmRecordUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/update', method: 'post', data }, option));
  },

  // 报警记录处理确定事件
  handlealarmRecordUpdate_save (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/update', method: 'post', data }, option));
  },
  // 获取报警记录的离床记录
  getoutBedRecordPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/outBedRecord/listPage', method: 'post', data }, option));
  },
  // 获取睡眠记录
  getsleepReportPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/sleepReport/listPage', method: 'post', data }, option));
  },
  // 睡眠报告 离床记录（不分页）
  sleepReportOutBedRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/outBedRecord/list', method: 'post', data }, option));
  },
  getSleepReportTime (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/sleepReport/detail', method: 'post', data }, option));
  },
  // 获取报警次数
  getAlertTime (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/countAlarm', method: 'post', data }, option));
  },
  // 个人获取操作记录分页
  getoperRecordPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/operRecord/listPage', method: 'post', data }, option));
  },

  /** 围栏等级 */
  // 围栏列表
  getRailListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/rail/listPage', method: 'post', data }, option));
  },
  // 保存围栏
  saveRail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/rail/save', method: 'post', data }, option));
  },

  /** 手环 */
  // 分页查询手环标签列表
  getLabelListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/labelListPage', method: 'post', data }, option));
  },
  // 不分页查询手环标签列表
  getLabelList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/labelList', method: 'post', data }, option));
  },
  // 删除手环标签
  deleteLabel (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/deleteLabel', method: 'post', data }, option));
  },
  // 绑定标签
  labelBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/add', method: 'post', data }, option));
  },
  // 修改绑定的标签
  labelBindUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/update', method: 'post', data }, option));
  },
  // 标签解绑
  labelBindDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/delete', method: 'post', data }, option));
  },
  // 修改标签报警围栏配置
  labelBindUpdatePrivInfo (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/updatePrivInfo', method: 'post', data }, option));
  },

  /** 签入签出 */
  // 病患签入
  signoutRecordAdd (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signoutRecord/add', method: 'post', data }, option));
  },
  // 病患查询签入详情
  getsignoutRecord (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signoutRecord/signInDetail', method: 'post', data }, option));
  },
  // 病患签出
  signoutRecordUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signoutRecord/update', method: 'post', data }, option));
  },
  // 病患签入签出记录
  signoutRecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signoutRecord/listPage', method: 'post', data }, option));
  },

  /** 历史轨迹 */
  // 获取历史轨迹
  getPatiHistoryTrack (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/map/history', method: 'post', data }, option));
  },

  /** 地图模式 */
  // 获取标签位置
  getLabelLocation (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/map/locate', method: 'post', data }, option));
  },
  // 根据楼层获取标签位置
  getLabelLocationByFloor (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/map/floor', method: 'post', data }, option));
  },

  // 查询病患列表（手环）(不分页)
  getPatientLabelList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientLabel/list', method: 'post', data }, option));
  },
  // 查询病患列表（手环）
  getPatientLabelListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientLabel/listPage', method: 'post', data }, option));
  },
  // 查询病患列表（地图）
  getPatientLabelFuzzyList (data = {}, option = {}) {
      return axios.request(Object.assign({ url: PROXY + '/api/pati/patientLabel/fuzzyList', method: 'post', data }, option));
  },
  // 查询病患详情（手环）
  getPatientLabelDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientLabel/detail', method: 'post', data }, option));
  },
  // 签入签出记录（手环）
  getCheckincheckoutrecordListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signoutRecord/listPage', method: 'post', data }, option));
  },
  // 住院记录
  hospitalizedListPage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientLabel/hospitalizedListPage', method: 'post', data }, option));
  },
  // 报警位置记录
  alarmRecordRfid (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmRecord/rfid', method: 'post', data }, option));
  },

  // 汇总统计（手环）
  count (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientLabel/statistics', method: 'post', data }, option));
  },

  // 基础设置
  // 修改预警默认设置
  updateAlarmSetting (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/alarmSettings/updateSettings', method: 'post', data }, option));
  },
  // 修改护理提醒默认设置
  updateNursingReminder (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/nursingReminder/updateReminder', method: 'post', data }, option));
  },
  // 删除睡眠默认设置
  deleteSleepSettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/sleepSettings/delete', method: 'post', data }, option));
  },
  // 根据病区查询位置列表接口
  getPatiListAreaBuild (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patient/listAreaBuild', method: 'post', data }, option));
  },
  // 查询配置详情(默认进入显示详情)
  getConfigDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/config/detail', method: 'post', data }, option));
  },
  // 修改配置(默认进入显示详情)
  updateConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/config/update', method: 'post', data }, option));
  },
  count2 (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/sleepSettings/delete', method: 'post', data }, option));
  },

  // 基础设置模块----保存全局设置
  Post_globalSetting (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/globalSettings/update', method: 'post', data }, option));
  },
  // 基础设置模块----全局设置详情
  Get_globalSetting (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/globalSettings/detail', method: 'post', data }, option));
  },
  // 基础设置模块----列表查询默认预警设置
  Get_defaultSettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/defaultSettings/detail', method: 'post', data }, option));
  },
  // 基础设置模块----保存预警设置
  POST_defaultSettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/defaultSettings/update', method: 'post', data }, option));
  },
  // 基础设置模块----查询设备设置详情
  GET_deviceSettingsDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/deviceSettings/detail', method: 'post', data }, option));
  },
  // 基础设置模块----保存设备设置详情
  POST_deviceSettingsDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/deviceSettings/update', method: 'post', data }, option));
  },
  // 基础设置模块----查询签出设置
  GET_signOutSettingDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signOutSetting/detail', method: 'post', data }, option));
  },
  // 基础设置模块----保存病区签出设置
  POST_signOutSettingDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signOutSetting/add', method: 'post', data }, option));
  },
  // 体征采集单获取、
  GET_signCollectList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signCollect/listPage', method: 'post', data }, option));
  },
  // 查询某天生效的体征采集设置
  GET_globalSettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/globalSettings/signCollcetSettings', method: 'post', data }, option));
  },
  // 分页查询病患定时任务记录
  GET_pati_taskRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/taskRecord/listPage', method: 'post', data }, option));
  },

  // 智慧看护 - 床位设置
  // 体征预分配 - 分页查询
  distributeListpage (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/distribute/listPage', method: 'post', data }, option));
  },
  // 体征预分配 - 分配/更换设备
  distributeSave (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/distribute/save', method: 'post', data }, option));
  },
  // 体征预分配 - 解除设备
  distributeDelete (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/distribute/delete', method: 'post', data }, option));
  },
  // 查询体征数据（不含心电）_gd
  GET_signsList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signs/list', method: 'post', data }, option));
  },
  // 一键解绑
  POST_signsBindonekey (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signsBind/onekey', method: 'post', data }, option));
  },
  // 病患管理 - 体征患者列表
  signsBedList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signs/bedList', method: 'post', data }, option));
  },
  // 病患管理 - 列表查询病患定时任务设置
  patientTaskList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientTask/list', method: 'post', data }, option));
  },
  // 查询病患入院以来有数据的时间选择器日期
  getPatiHaveData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/indata/list', method: 'post', data }, option));
  },
  // 查询心电数据
  getecgList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signs/ecgList', method: 'post', data }, option));
  },
  // 查询心电数据，10s内
  getECG_tenData (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signs/ecgTen', method: 'post', data }, option));
  },
  // 病患管理 - 体征绑定 - 绑定详情
  signsBindDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signsBind/detail', method: 'post', data }, option));
  },
  // 病患管理 - 体征绑定 - 监测体征配置
  signsBindMonitors (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signsBind/monitors', method: 'post', data }, option));
  },
  // 病患管理 - 体征绑定 - 绑定/换绑
  signsBindBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signsBind/bind', method: 'post', data }, option));
  },
  // 病患管理 - 体征绑定 - 解绑
  signsBindUnbind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signsBind/unbind', method: 'post', data }, option));
  },
  // 病患管理 - 定时任务 - 保存病患定时任务设置
  patientTaskUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientTask/update', method: 'post', data }, option));
  },
  // 病患管理 - 预警设置 - 列表查询病患预警设置
  patientSettingsList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientSettings/list', method: 'post', data }, option));
  },
  // 病患管理 - 预警设置 - 保存病患预警设置
  patientSettingsUpdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patientSettings/update', method: 'post', data }, option));
  },
  // 病患管理 - 预警设置 - 查询全部设置
  globalSettingsQuerySettings (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/globalSettings/querySettings', method: 'post', data }, option));
  },
  // 病患管理 - 病患最近一条的体征数据
  signsQueryLast (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signs/queryLast', method: 'post', data }, option));
  },
  // 病患管理 - 列表查询未处理定时任务提醒
  taskRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/taskRecord/list', method: 'post', data }, option));
  },
  // 基础设置/终端管理 - 列表查询设备设置
  deviceSettingsList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/deviceSettings/list', method: 'post', data }, option));
  },
  // 基础设置/终端管理 - 处理定时任务提醒
  taskRecordProcess (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/taskRecord/process', method: 'post', data }, option));
  },
  // 智慧看护 - 病患管理 - 手动新增病患
  addPatient (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/patient/addPatient', method: 'post', data }, option));
  },
  // 智慧看护 - 病患管理 - 编辑病患信息
  editPatient (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/patient/edit', method: 'post', data }, option));
  },
  // 智慧看护 - 病患管理 - 获取最大病患名称
  getMaxNameValue (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/patient/getMaxNameValue', method: 'post', data }, option));
  },
  // 智慧看护 - 病患管理 - 病患出院
  outHospital (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patient/outHospital', method: 'post', data }, option));
  },
  // 新增/编辑个人设置
  userConfigupdate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/userConfig/update', method: 'post', data }, option));
  },
  userConfigdetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/userConfig/detail', method: 'post', data }, option));
  },
  // 智慧看护 - 历史轨迹可用日期查询
  getUsableDate (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/map/getUsableDate', method: 'post', data }, option));
  },
  // 智慧看护 - 查询病患信息 - 查询流水号判重
  patientDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/sys/patient/detail', method: 'post', data }, option));
  },

  // 智慧看护 - 定位看护 - 手环出院解绑
  outHospitalAndUnbind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/labelBind/outHospitalAndUnbind', method: 'post', data }, option));
  },

  // 智慧看护 - 终端设置 - 一键报警终端
  getGuardList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/guard/listPage', method: 'post', data }, option));
  },
  // 智慧看护 - 终端设置 - 所有一键报警终端
  getGuardAllList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/guard/list', method: 'post', data }, option));
  },
  // 智慧看护 - 终端设置 - 删除一键报警终端
  deteleGuard (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/guard/delete', method: 'post', data }, option));
  },
  // 智慧看护 - 终端设置 - 新增一键报警终端
  addGuard (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/guard/save', method: 'post', data }, option));
  },
  // 智慧看护 - 终端设置 - 编辑一键报警终端
  editGuard (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/guard/update', method: 'post', data }, option));
  },

  // 智慧看护 - 房间管理 - 查询房间列表
  getRoomGuardBindList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/roomGuardBind/roomList', method: 'post', data }, option));
  },

  // 智慧看护 - 基础设置 - 查询报警声音设置
  getVoiceConfigList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/voice/config/list', method: 'post', data }, option));
  },

  // 智慧看护 - 基础设置 - 设置报警声音设置
  setVoiceConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/voice/config/update', method: 'post', data }, option));
  },

  // 智慧看护 - 房间管理 - 绑定设备
  addRoomGuardBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/roomGuardBind/add', method: 'post', data }, option));
  },

  // 智慧看护 - 房间管理 - 解绑设备
  deleteRoomGuardBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/roomGuardBind/delete', method: 'post', data }, option));
  },

  // 智慧看护 - 房间管理 - 更绑设备
  updateRoomGuardBind (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/roomGuardBind/update', method: 'post', data }, option));
  },

  // 智慧看护 - 终端管理 - 一体机管理
  getOneMachineList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signAio/listPage', method: 'post', data }, option));
  },

  // 智慧看护 - 终端管理 - 新增一体机
  addOneMachine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signAio/add', method: 'post', data }, option));
  },

  // 智慧看护 - 终端管理 - 编辑一体机
  editOneMachine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signAio/update', method: 'post', data }, option));
  },

  // 智慧看护 - 终端管理 - 编辑一体机
  deleteOneMachine (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signAio/delete', method: 'post', data }, option));
  },

  // 智慧看护 - 查询第三方消息设置
  getThirdMsgConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/thirdMsgConfig/list', method: 'post', data }, option));
  },

  // 智慧看护 - 更新第三方消息设置
  updateThirdMsgConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/thirdMsgConfig/update', method: 'post', data }, option));
  },
  // 2.5.1
  // 查询自动绑定患者设置
  getMattressBindConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/mattress/bind/config/detail', method: 'post', data }, option));
  },

  // 更新自动绑定患者设置
  updateMattressBindConfig (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/mattress/bind/config/update', method: 'post', data }, option));
  },
  // 2.6.0
  // 呼叫记录分页查询 http://showdoc.xinsec.com.cn/web/#/83/4866
  getCallRecordList (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/call/record/listPage', method: 'post', data }, option));
  },
  // 呼叫记录详情
  getCallRecordDetail (data = {}, option = {}) {
    return axios.request(Object.assign({ url: PROXY + '/api/pati/call/record/detail', method: 'post', data }, option));
  },
    // 呼叫记录详情
  updateCallRecord (data = {}, option = {}) {
      return axios.request(Object.assign({ url: PROXY + '/api/pati/call/record/update', method: 'post', data }, option));
  },
  // 查询病患配置 http://showdoc.xinsec.com.cn/web/#/83/4896
  getPrivInfo(data = {}, option = {}){
      return axios.request(Object.assign({ url: PROXY + '/api/pati/patient/config/detail', method: 'post', data }, option));
  },
  // 执行血压计测量 
  startMeasuring(data = {}, option = {}){
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signs/bp/start', method: 'post', data }, option));
  },
  // 保存血压计测量 
  saveMeasurResult(data = {}, option = {}){
    return axios.request(Object.assign({ url: PROXY + '/api/pati/signs/bp/save', method: 'post', data }, option));
  },
  // 更新患者设置
  updatePatientConfig (data = {}, option = {}){
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patient/config/update', method: 'post', data }, option));
  },
  // 获取患者设置
  getPatientConfigDetail (data = {}, option = {}){
    return axios.request(Object.assign({ url: PROXY + '/api/pati/patient/config/detail', method: 'post', data }, option));
  }
};

export default patiAPI;
