import i18n from '@/i18n/i18n';
import apis from '@/api/index';
export default {
  namespaced: true,
  state: {
    // 资产状态
    assetsStatusObj: {
      // 空闲
      free: {
        value: 0,
        label: i18n.t('assets.leisure'),
        color: '#3FA0FF'
      },
      // 已申领
      use: {
        value: 1,
        label: i18n.t('assets.claimed'),
        color: '#3bd190'
      },
      // 报废
      scrap: {
        value: 2,
        label: i18n.t('assets.scrap'),
        color: '#ff5d5d'
      },
      // 借用
      borrowed: {
        value: 3,
        label: i18n.t('assets.borrow'),
        color: '#FAAD14'
      },
      // 维修
      repair: {
        value: 4,
        label: i18n.t('assets.maintain'),
        color: '#ff5d5d'
      },
      // 遗失
      loss: {
        value: 5,
        label: i18n.t('assets.lose'),
        color: '#ff5d5d'
      },
      // 清理
      clear: {
        value: 8,
        label: '已清理',
        color: '#ff5d5d'
      }
    },
    // 资产操作状态
    operationStatusObj: {
      // 管理
      manage: {
        value: 1,
        label: '管理',
        color: '#3bd190'
      },
      // 申领
      apply: {
        value: 2,
        label: i18n.t('assets.apply'),
        color: '#3bd190'
      },
      // 借用
      borrowed: {
        value: 3,
        label: i18n.t('assets.borrow'),
        color: '#3bd190'
      },
      // 维修
      repair: {
        value: 4,
        label: i18n.t('assets.maintain'),
        color: '#ff5d5d'
      },
      // 报废
      scrap: {
        value: 5,
        label: i18n.t('assets.scrap'),
        color: '#ff5d5d'
      },
      // 遗失
      loss: {
        value: 6,
        label: i18n.t('assets.lose'),
        color: '#ff5d5d'
      },
      // 盘点
      check: {
        value: 7,
        label: '盘点',
        color: '#666'
      },
      // 绑定
      bind: {
        value: 8,
        label: '绑定',
        color: '#666'
      },
      // 绑定
      return: {
        value: 9,
        label: i18n.t('assets.return'),
        color: '#666'
      },
      // 维修完成
      repairComplete: {
        value: 10,
        label: i18n.t('assets.MaintenanceCompleted'),
        color: '#3bd190'
      },
      // 空闲
      free: {
        value: 11,
        label: i18n.t('assets.leisure'),
        color: '#606266'
      }

    },
    // 资产操作记录模板
    operationRecord: {
      // 资产编辑
      editAssets: {
        value: 1,
        label: '编辑'
      },
      // 资产申领
      apply: {
        value: 2,
        label: i18n.t('assets.apply')
      },
      // 借用
      borrowed: {
        value: 3,
        label: i18n.t('assets.borrow')
      },
      // 退还
      return: {
        value: 4,
        label: '退还'
      },
      // 维修 // 报修 其实是同一种状态,在使用资产和管理资产页面中文案不同
      repair: {
        value: 5,
        label: i18n.t('assets.maintain')
      },
      // 遗失
      loss: {
        value: 6,
        label: i18n.t('assets.lose')
      },
      // 盘点
      // check: {
      //   value: 7,
      //   label: '盘点'
      // },
      // 报废
      scrap: {
        value: 8,
        label: i18n.t('assets.scrap')
      },
      // 维修完成
      repairSuccess: {
        value: 9,
        label: '维修完成'
      },
      // 标签绑定
      bind: {
        value: 10,
        label: '标签绑定'
      },
      // 标签解绑
      unBind: {
        value: 11,
        label: '标签解绑'
      },
      // 标签更绑
      updateBind: {
        value: 12,
        label: '标签更绑'
      },
      // 资产找回
      lossReturn: {
        value: 13,
        label: '找回'
      },
      clearReturn: {
        value: 16,
        label: '清理'
      }
    },
    source: [{
      label: '自购',
      value: 1
    }, {
      label: '租赁',
      value: 2
    }, {
      label: '赠送',
      value: 3
    }, {
      label: '借用',
      value: 4
    }, {
      label: '自产',
      value: 5
    }, {
      label: '其他',
      value: 0
    }],
    noticeList: [],
    templateList: [
      {
        templateId: 1,
        templateName: '50mm*70mm模版1',
        width: 50,
        height: 70,
        defaultFlag: 1
      },
      {
        templateId: 2,
        templateName: '81mm*36mm模版2',
        width: 81,
        height: 36,
        defaultFlag: 0
      },
      {
        defaultFlag: 0,
        height: 30,
        templateId: 3,
        templateName: '70mm*30mm模版3',
        width: 70
      },
      {
        defaultFlag: 0,
        height: 20,
        templateId: 4,
        templateName: '73mm*20mm模版4',
        width: 73
      }
    ],
    assetsSelectData: [],
    asset_desc_customize: {
      hospital: '院区',
      dept: '科室',
      area: '病区'
    },
    asseMapSetting: '1', // 地图资产定位规则
    asse_camera_config: {},
    currentPrintSetting:{} // 当前打印机设置
  },
  mutations: {
    setAsseKeyValue (state, params){
      if (params.key) {
        state[params.key] = params.value
      }
    },
    setNoticeList (state, noticeList) {
      state.noticeList = noticeList;
    },
    pushNoticeList (state, notice) {
      state.noticeList.unshift(notice);
    },
    setAssetsSelectData (state, data) {
      state.assetsSelectData = data;
    },
    setAssetDescCustomize (state, data) {
      state.asset_desc_customize = data;
    },
    setAsseMapSetting (state, data) {
      state.asseMapSetting = data;
    },
    setAsseCameraConfig (state, data) {
      state.asse_camera_config = data
    }
  },
  actions: {
    getAssetDescCustomize ({ commit }, data) {
      apis.sys.getDictList({
        dictType: 'asset_desc_customize'
      }).then(res => {
        let resData = res.data;
        let asset_desc_customize = {};
        if (resData && resData.length > 0) {
          resData.map(i => {
            asset_desc_customize[i.dictCode] = i.remark || i.dictValue;
          });
          commit('setAssetDescCustomize', asset_desc_customize);
        }
      }).catch(err => console.log('asset_desc_customize', err));
    },
    getAsseMapSetting ({ commit }, data) {
      apis.sys.getDictList({
        dictType: 'asse_map_setting'
      }).then(res => {
        const resData = res.data;
        if (resData && resData.length > 0) {
          const asse_map_setting = resData[0].dictValue;
          commit('setAsseMapSetting', asse_map_setting);
        }
      }).catch(err => console.log('asse_map_setting', err));
    },
    getAssetCameraConfig({ commit }, data) {
      apis.sys.getDictList({
        dictType: 'asse_camera_config'
      }).then(res => {
        const resData = res.data;
        if (resData && resData.length > 0) {
          const asse_camera_config = resData[0].dictValue;
          commit('setAsseCameraConfig', asse_camera_config ? JSON.parse(asse_camera_config) : {});
        }
      }).catch(err => console.log('asse_camera_config', err));
    }
  }
};
